//
// TLS Dose Engine Home Page
//
const HomeTab = () => {
    const style = {
        "maxWidth": "20 em"
    };

    return (
        <div>
            <h1>TLS Dose Engine</h1>
            <div className="card text-white bg-primary mb-3" style={style}>
                <div className="card-header">Dose Calculation</div>
                <div className="card-body">
                    <h4 className="card-title">Monte Carlo Simulation</h4>
                    <p className="card-text">The dose engine coordinates all activities in the BNCT dose calculation cycle,
                        from file transfer to the computation computer to monitoring of the calculation job</p>
                </div>
            </div>
            <div className="card bg-light mb-3" style={style}>
                <div className="card-header">Jobs</div>
                <div className="card-body">
                    <h4 className="card-title">Monitor</h4>
                    <p className="card-text">Real time progress of dose calculation</p>
                </div>
            </div>
            <div className="card bg-light mb-3" style={style}>
                <div className="card-header">Files</div>
                <div className="card-body">
                    <h4 className="card-title">Transfer</h4>
                    <p className="card-text">Download RayStation files and upload dose files</p>
                </div>
            </div>
            <div className="card bg-light mb-3" style={style}>
                <div className="card-header">Logs</div>
                <div className="card-body">
                    <h4 className="card-title">Tail</h4>
                    <p className="card-text">View job logs in real time as the dose calculation runs</p>
                </div>
            </div>
        </div>
    );
}

export default HomeTab;