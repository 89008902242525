//
// Text display dialog.
//
// params:
//   bool readonly           - if not true then show Edit button
//   function onEdit(string) - returns modified text
//   function onClose()      - to be called on close to provide modifed text
//
// https://www.pluralsight.com/guides/working-with-bootstraps-modals-react
//
import 'bootstrap/dist/js/bootstrap.min.js';
import Modal from "react-bootstrap/Modal";
import { useState } from 'react';

const TextDialog = (params) => {
    const [isOpen, setIsOpen] = useState(true);

    const hideModal = () => {
        setIsOpen(false);
    };

    function onClose() {
        if (params.onClose) {
            params.onClose();
        }

        hideModal();
    }
    function onEdit() {
        let widget = document.getElementById("w_TextDialogTextarea");

        if (params.onEdit) {
            params.onEdit(widget.value);
        }

        hideModal();
    }

    return (
        <div>
            <Modal show={isOpen}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="w_TextDialogHeader">{params.header}</h5>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label className="col-form-label mt-4" htmlFor="w_TextDialogTextarea">Text</label>
                            <textarea className="form-control" id="w_TextDialogTextarea" rows="16" defaultValue={params.text}></textarea>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={onClose}>Close</button>
                        {!params.readonly &&
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={onEdit}>Edit</button>
                        }
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default TextDialog;