//
// TLS Dose Engine machine commissioning
//
import { useState, useEffect } from 'react';

import gUtilities from '../script/utilities';
import gSession from '../script/session.js';
import MachineDialog from './MachineDialog.js';
import TableWidget from './TableWidget';
import Machine from '../script/machine';

const CommissioningPage = () => {
    const [mode, setMode] = useState("view");
    const [machine, setMachine] = useState(null);
    const [machines, setMachines] = useState([]);

    async function getMachines() {
        let request = {
            service: "machine.list",
            noncommissioned: false
        };

        let response = await gSession.exchange(request);

        if (response.isError) {
            gUtilities.showError("Fetch Machine List", response.message);
            return;
        }

        let device;
        let devices = [];
        for (let record of response) {
            if (record.version === "0.2") {
                device = new Machine(record);
                devices.push(device);
            }
        }

        setMachines(devices);
    }

    function onMachineCreated() {
        getMachines();

        setMachine(null);
    }

    function onCreate() {
        setMode("create");
        setMachine(new Machine());
    }

    function onEdit(data) {
        setMode("edit");
        setMachine(data.machine);
    }

    function onClone(data) {
        setMode("clone");
        setMachine(data.machine);
    }

    function onView(data) {
        setMode("view");
        setMachine(data.machine);
    }

    function onCloseMachine() {
        setMachine(null);
        getMachines();
    }

    let buttonData = {};

    if (gSession.isProduction()) {
        buttonData.buttons = [
            {
                label: "View",
                callback: onView
            }
        ];
    } else {
        buttonData.buttons = [
            {
                label: "Edit",
                callback: onEdit
            },
            {
                label: "Clone",
                callback: onClone
            }
        ];
    };

    function generateMachineTable() {
        let data = {
            header: [
                "Name",
                "Model",
                "Simulator",
                "Energy (MV)",
                ""  // won't display in table
            ],
            columns: [
                "name",
                "model",
                "simulator_name",
                "energy",
                "machine"
            ],
            rows: []
        };

        let row;

        for (let device of machines) {
            row = [
                device.name(),
                device.model(),
                device.simulator_name(),
                device.energy(),
                device
            ];

            data.rows.push(row);
        }

        data.rows.sort((l, r) => {
            if (l < r) {
                return -1;
            } else {
                return 1;
            }
        });

        return data;
    }

    useEffect(() => {
        getMachines();
    }, []);

    return (
        <div>
            <h1><span>Machine Commissioning</span></h1>
            <TableWidget data={generateMachineTable()} buttonData={buttonData} />
            <button className="btn btn-primary" type="button" id="w_CreateMachineButton" onClick={onCreate}>Create</button>

            {machine &&
                <MachineDialog machine={machine} mode={mode} onCreate={onMachineCreated} onClose={onCloseMachine} />
            }
        </div >
    );
}

export default CommissioningPage;