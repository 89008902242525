import { useEffect, useState } from 'react';
import 'bootstrap/dist/js/bootstrap.min.js';
import Modal from "react-bootstrap/Modal";
import gEventRouter from "../script/event_router.js";
import gUtilities from '../script/utilities';
import gSession from '../script/session';

import ConfirmPasswordDialog from './ConfirmPasswordDialog.js';

const gResetEvents = {
    S_CONFIGURE: "E_CONFIGURED",
    S_WRITE_INPUT: "E_INPUT",
    S_COMPUTING: "E_OUTPUT",
    S_FAILURE: "E_FAILURE"
};

function JobDialog() {
    const [jobId, setJobId] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState(null);
    const [event, setEvent] = useState(null);
    const [actionPending, setActionPending] = useState(false);
    const [verifyPassword, setVerifyPassword] = useState(false);

    const showModal = (data) => {
        let job = data;

        setJobId(job.job_id);
        setIsOpen(true);
        setData(null);
        getData(job.job_id);
    };

    const hideModal = () => {
        setIsOpen(false);
    };

    gEventRouter.register("JobDialog.showModal", showModal);

    function allowDeleteButton() {
        if (!data) {
            return false;
        }

        let isStopped = data.state === "S_COMPLETED" || data.state === "S_FAILURE";

        if (isStopped && gSession.isInGroup("job")) {
            return true;
        } else {
            return false;
        }
    }

    function allowCancelButton() {
        if (!data) {
            return false;
        }

        let isRunning = data.state !== "S_COMPLETED" && data.state !== "S_FAILURE";

        if (isRunning && gSession.isInGroup("job")) {
            return true;
        } else {
            return false;
        }
    }

    function allowLaunchButton() {
        if (!data) {
            return false;
        }

        let isPending = data.state === "S_PENDING";
        let isInJob = gSession.isInGroup("job");
        let isInMcnp = gSession.isInGroup("mcnp");
        let isMcnp = data.simulator === "mcnp";

        if (isPending && isInJob) {
            if (isMcnp) {
                return isInMcnp;
            }

            return true;
        } else {
            return false;
        }
    }

    function allowResetButton() {
        if (!data) {
            return false;
        }

        return data.simulator === "mcnp";
    }

    function allowArchiveButton() {
        if (!data) {
            return false;
        }

        return data.state === "S_COMPLETED" && (!data.archive_status || data.archive_status === "active");
    }

    function onRemove() {
        let data = {
            action: "Delete Job",
            message: "Permanently remove this job",
            callback: (decision) => {
                if (decision) {
                    remove();
                }
            }
        };

        gEventRouter.invoke("ConfirmationDialog.showModal", data);
    }

    async function remove() {
        let request = {
            service: "job.remove",
            job_id: jobId
        };

        let response = await gSession.exchange(request);
        if (response.isError) {
            gUtilities.showError("Delete Job", response.message);
        } else {
            hideModal();
        }
    }

    function onCancel() {
        let data = {
            action: "Cancel Job",
            message: "Stop processing of this job",
            callback: (decision) => {
                if (decision) {
                    cancel();
                }
            }
        };

        gEventRouter.invoke("ConfirmationDialog.showModal", data);
    }

    async function cancel() {
        let request = {
            service: "job.cancel",
            job_id: jobId
        };

        let response = await gSession.exchange(request);
        if (response.isError) {
            gUtilities.showError("Cancel Job", response.message);
        } else {
            hideModal();
        }
    }

    async function onLaunch() {
        if (data.simulator === "mcnp") {
            setVerifyPassword(true);
        } else {
            launch();
        }
    }

    async function launch() {
        let request = {
            service: "job.run",
            job_id: jobId
        };

        let response = await gSession.exchange(request);
        if (response.isError) {
            gUtilities.showError("Launch Job", response.message);
        } else {
            hideModal();
        }
    }

    async function onPasswordVerification(status) {
        setVerifyPassword(false);

        if (status === "SUCCESS") {
            launch();
        } else if (status === "CANCELED") {
        } else {
            gUtilities.showError("Verify Password", "User is not authorized to launch MCNP");
        }
    }

    function onArchive() {
        let data = {
            action: "Archive Job",
            message: "Zip and store this job",
            callback: (decision) => {
                if (decision) {
                    archive();
                }
            }
        };

        gEventRouter.invoke("ConfirmationDialog.showModal", data);
    }

    async function archive() {
        let request = {
            service: "job.archive",
            job_id: jobId
        };

        let response = await gSession.exchange(request);
        if (response.isError) {
            gUtilities.showError("Archive Job", response.message);
        } else {
            hideModal();
        }
    }

    async function getData(job_id) {
        let request = {
            service: "job.data",
            job_id: job_id
        };

        let response = await gSession.exchange(request);

        if (response.isError) {
            gUtilities.showError("Job Data", response.message);
        }

        let jdata = {
            state: response.state,
            progress: response.progress,
            datetime: response.datetime,
            simulator: response.machine.simulator.name,
            machine: response.machine.name,
            station: response.station,
            uncertainty: response.uncertainty,
            error_max: "",
            converged: response.converged ? "true" : "false",
            particles: response.particle_count
        }

        let max;
        let name;
        let report;
        let beam_number = 1;

        do {
            name = `report-${beam_number}`;
            if (response.hasOwnProperty(name)) {
                report = response[name];
                max = report.error ? report.error.max : report.max;
                jdata.error_max += ` ${max}`;
            }
            else {
                break;
            }

            beam_number++;
        } while (true);

        setData(jdata);
    }

    async function onReset() {
        let widget = document.querySelector("#w_JobStateSelect");
        let state = widget.options[widget.selectedIndex].text;

        if (!gResetEvents.hasOwnProperty(state)) {
            gUtilities.showError("Reset State", "No state selected");
            return;
        }

        let request = {
            service: "job.reset",
            job_id: jobId,
            data: {
                state: state
            }
        };

        let response = await gSession.exchange(request);

        if (response.isError) {
            gUtilities.showError("Job Reset State", response.message);
        } else {
            setEvent(gResetEvents[state]);
            setActionPending(true);
            getData(jobId);
        }
    }

    async function onContinue() {
        let request = {
            service: "job.continue",
            job_id: jobId,
            data: {
                event: event
            }
        };

        let response = await gSession.exchange(request);

        if (response.isError) {
            gUtilities.showError("Job Fire Event", response.message);
        } else {
            setActionPending(false);
            hideModal();
        }
    }

    async function onReload() {
        let request = {
            service: "job.reload",
            job_id: jobId,
            data: {
                event: event
            }
        };

        let response = await gSession.exchange(request);

        if (response.isError) {
            gUtilities.showError("Job reload", response.message);
        } else {
            setActionPending(false);
            hideModal();
        }
    }

    function onSelectState() {
        let widget = document.querySelector("#w_JobStateSelect");
        let state = widget.options[widget.selectedIndex].text;

        let descrptions = {
            S_CONFIGURE: "Resume after editing configuration",
            S_WRITE_INPUT: "Resume after editing input files",
            S_COMPUTING: "Resume after simulator exit",
            S_FAILURE: "Move to failure state"
        };

        widget = document.querySelector("#w_JobStateDescriptionField");
        widget.value = descrptions[state];
    }


    useEffect(() => {
        let widget = document.querySelector("#w_JobJobIdField");
        if (widget) {
            widget.value = jobId;
        }
    }, [data]);

    return (
        <div>
            <Modal show={isOpen}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Job Actions</h5>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label className="col-form-label mt-1" htmlFor="w_JobJobIdField">Job ID</label>
                            <input type="text" className="form-control" readOnly="" placeholder="Job ID" id="w_JobJobIdField"></input>
                            <ul className="list-group">
                                {
                                    data && Object.keys(data).map(key => (
                                        <li key={key} className="list-group-item d-flex justify-content-between align-items-center">
                                            {key}
                                            <span className="badge bg-primary rounded-pill">{data[key]}</span>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                        {
                            allowResetButton() &&
                            <>
                                <label htmlFor="w_JobStateSelect" className="form-label mt-1">Reset Job State</label>
                                <div className="input-group mb-1">
                                    <select className="form-select" onChange={onSelectState} id="w_JobStateSelect" aria-describedby="w_JobResetButton">
                                        <option>Select</option>
                                        {
                                            Object.keys(gResetEvents).map(key => (
                                                <option key={key}>{key}</option>
                                            ))
                                        }
                                    </select>
                                    <button type="button" className="btn btn-primary" id="w_JobResetButton" onClick={onReset}>Reset</button>
                                </div>
                                <label className="col-form-label mt-1" htmlFor="w_JobStateDescriptionField">Description</label>
                                <input type="text" className="form-control" readOnly="" placeholder="Select" id="w_JobStateDescriptionField"></input>
                            </>
                        }
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={hideModal}>Close</button>
                        {
                            allowCancelButton() &&
                            <button type="button" className="btn btn-primary" id="w_JobCancelButton" onClick={onCancel}>Cancel</button>
                        }
                        {
                            allowDeleteButton() &&
                            <button type="button" className="btn btn-primary" id="w_JobDeleteButton" onClick={onRemove}>Delete</button>
                        }
                        {
                            allowLaunchButton() &&
                            <button type="button" className="btn btn-primary" id="w_JobLaunchButton" onClick={onLaunch}>Launch</button>
                        }
                        {
                            allowArchiveButton() &&
                            <button type="button" className="btn btn-primary" id="w_JobArchiveButton" onClick={onArchive}>Archive</button>
                        }
                        {
                            allowResetButton() &&
                            <>
                                <button type="button" disabled={!actionPending} className="btn btn-primary" id="w_JobContinueButton" onClick={onContinue}>Continue</button>
                                <button type="button" disabled={!actionPending} className="btn btn-primary" id="w_JobReloadButton" onClick={onReload}>Reload</button>
                            </>
                        }
                    </div>
                </div>
            </Modal>
            {verifyPassword &&
                <ConfirmPasswordDialog onConfirm={onPasswordVerification} />
            }
        </div>
    )
}

export default JobDialog;