//
// TLS Dose Engine About Page
//
import gSession from '../script/session';

const AboutPage = () => {

    const version = () => {
        return gSession.version();
    }

    // TODO: Why doesn't this work?
    const style = {
        "maxWidth": "200 px",
        "borderTop": "40 px",
        "marginTop": "40 px",
        "backgroudColor": "red"
    };

    return (
        <div style={style}>
            <h1><span></span></h1>
            <div className="card text-white bg-primary mb-3">
                <div className="card-header">About</div>
                <div className="card-body">
                    <h4 className="card-title">TLS Dose Calculation Service</h4>
                    <p className="card-text">Network services and Monte Carlo dose calculation for BNCT treatment.</p>
                    <p className="card-text">{version()}</p>
                    <p className="card-text"><i>Copyright &copy; 2023 TAE Life Sciences</i></p>
                </div>
            </div>
        </div>
    );
}

export default AboutPage;