//
// Machine editor dialog.
//
import 'bootstrap/dist/js/bootstrap.min.js';
import Modal from "react-bootstrap/Modal";
import { useState } from 'react';

import gSession from '../script/session.js';
import gUtilities from '../script/utilities';
import gEventRouter from "../script/event_router.js";

import TextAssign from './TextAssign';
import TextDisplay from './TextDisplay';
import TableWidget from './TableWidget';
import SelectAssign from './SelectAssign';
import FileUploader from './FileUploader';
import CollimatorDialog from './CollimatorDialog.js';

const MachineDialog = (props) => {
    const [tab, setTab] = useState("");  // e.g. settings, files, collimators
    const [isOpen, setIsOpen] = useState(true);
    const [refresh, setRefresh] = useState(null);
    const [defined, setDefined] = useState(false);
    const [collimatorValidator, setCollimatorValidator] = useState(null);

    let validator = props.machine.validator();

    const hideModal = () => {
        setIsOpen(false);
        setRefresh(null);

        validator.popCallback();

        if (props.onClose) {
            props.onClose();
        }
    };

    // first render
    if (refresh === null) {
        props.machine.validator().pushCallback(setRefresh);
        setRefresh({});

        if (props.mode === "view") {
            setDefined(true);
        } else if (props.mode === "create") {
            setDefined(false);
        } else if (props.mode === "edit") {
            setDefined(props.machine.defined());
        } else if (props.mode === "clone") {
            setDefined(false);
            // set a dummy name and then invalidate it
            validator.validate("machine.name", "CLONE");
            validator.invalidate("machine.name");
        } else {
            gUtilities.showError("Edit Machine", "Unknown mode '{0}'".format(props.mode));
            hideModal();
        }

        setTab("identification");
    }

    function showTab(name) {
        setTab(name);
    }

    function generateCollimatorTable() {
        let header = [
            "Slot",
            "Name",
            "Size (cm)",
            "Status"
        ];

        let columns = [
            "slot",
            "name",
            "size",
            "status"
        ];

        let rows = [];
        let row;
        let tree = props.machine.tree();
        for (let collimator of Object.values(tree.collimators)) {
            row = [];
            row.push(collimator.slot);
            row.push(collimator.name);
            row.push(collimator.size);
            row.push(collimator.status);

            rows.push(row);
        }

        // sort on slot name (column 0)
        rows = rows.sort((l, r) => {
            if (l[0] < r[0]) {
                return -1;
            }

            return 1;
        });

        let data = {
            header: header,
            columns: columns,
            rows: rows
        };

        return data;
    }

    function generatePendingItemsTable() {
        let invalid = validator.list(false);

        let result = [];
        for (let item of invalid) {
            result.push({
                "Item": item,
                "Valid": "False"
            });
        }

        result.sort((l, r) => {
            if (l.Item < r.Item) {
                return -1;
            }

            return 1;
        });

        return gUtilities.convertDbResultToTableData(result);
    }

    async function onSubmit() {
        if (!props.machine.valid()) {
            gUtilities.showError("Create Machine", "Machine data is not valid");
            return;
        }

        let service;
        if (props.mode === "edit") {
            service = "machine.update";
        } else if (props.mode === "create" || props.mode === "clone") {
            service = "machine.create";
        }

        let request = {
            service: service,
            data: props.machine.tree()
        }

        let response;
        try {
            response = await gSession.exchange(request);
        } catch (ex) {
            response = { isError: true, message: ex.toString() };
        }

        if (response.isError) {
            gUtilities.showError("Submit Machine", "Create failed: " + response.message);
        } else {
            props.onCreate();
            hideModal();
        }
    }

    function onEditCollimator(row) {
        let cvalidator = getCollimator(row.slot);

        setCollimatorValidator(cvalidator);
    }

    function getCollimator(slot) {
        if (typeof (slot) === 'number') {
            slot = props.machine.numberToSlotName(slot);
        }

        let cvalidator = validator.child(`machine.collimators.${slot}`);

        if (!cvalidator) {
            gUtilities.showError("Find Collimator", `Failed to find collimator ${slot}`);
        }

        return cvalidator;
    }

    // Verify that the proposed collimator name does not exist in the machine
    function onNameCollimator(name) {
        if (name === collimatorValidator.value("self.name")) {
            return true;
        }

        let collimators = validator.child(`machine.collimators`).tree();
        for (let collimator of Object.values(collimators)) {
            if (collimator.name === name) {
                return false;
            }
        }

        return true;
    }

    function onSaveCollimator() {
    }

    let buttonData = {
        buttons: [
            {
                label: "Edit",
                callback: onEditCollimator
            }
        ]
    };

    async function onAssignName(value) {
        let request = {
            service: "machine.register",
            name: value
        };

        let response = await gSession.exchange(request);

        let status = false;
        if (response.isError) {
            gUtilities.showError("Register Machine Name", response.message);
            validator.invalidate('machine.name');
        } else {
            status = true;
            validator.validate('machine.machine_id', response.machine_id);
        }

        return status;
    }

    function allowSubmit() {
        let allow = defined && props.mode !== "view" && validator.allValid();

        return allow;
    }

    function allowDefine() {
        return !defined && validator.query("machine.name") && validator.query("machine.simulator.name") && validator.query("machine.model");
    }

    function onClose() {
        let data = {
            action: "Cancel Machine Creation",
            message: "Discard Edits",
            callback: (decision) => {
                if (decision) {
                    hideModal();
                }
            }
        };

        if (validator.anyValid()) {
            gEventRouter.invoke("ConfirmationDialog.showModal", data);
        } else {
            hideModal();
        }
    }

    function onDefine() {
        if (props.mode === "create") {
            props.machine.define();
            props.machine.addSettings();
            props.machine.addStandardCollimators();
        }

        setDefined(true);
        setRefresh({});
    }

    function onCloseCollimatorDialog() {
        setCollimatorValidator(null);

        setRefresh({});
    }

    function submitButtonText() {
        let text;
        if (props.mode === "create") {
            text = "Create";
        } else if (props.mode === "edit") {
            text = "Update";
        } else if (props.mode === "clone") {
            text = "Create";
        } else {
            gUtilities.showError("Submit Machine", "Unknown mode '{0}'".format(props.mode))
        }

        return text;
    }

    return (
        <div id="w_MachineDialog">
            <Modal show={isOpen} size="lg">
                <Modal.Header>
                    <Modal.Title>Machine Data Editor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-content">
                        <div className="modal-body">
                            <div>
                                <ul className="nav nav-tabs">
                                    <li className="nav-item">
                                        <a className="nav-link active" data-bs-toggle="tab" href="#w_MachineContentDiv"
                                            onClick={() => { showTab("identification") }}>Identification</a>
                                    </li>
                                    {defined &&
                                        <>
                                            <li className="nav-item">
                                                <a className="nav-link" data-bs-toggle="tab" href="#w_MachineContentDiv"
                                                    onClick={() => { showTab("description") }}>Description</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-bs-toggle="tab" href="#w_MachineContentDiv"
                                                    onClick={() => { showTab("beam") }}>Beam</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-bs-toggle="tab" href="#w_MachineContentDiv"
                                                    onClick={() => { showTab("settings") }}>Settings</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-bs-toggle="tab" href="#w_MachineContentDiv"
                                                    onClick={() => { showTab("files") }}>Files</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-bs-toggle="tab" href="#w_MachineContentDiv"
                                                    onClick={() => { showTab("collimators") }}>Collimators</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-bs-toggle="tab" href="#w_MachineContentDiv"
                                                    onClick={() => { showTab("pending") }}>Pending</a>
                                            </li>
                                        </>
                                    }
                                </ul>

                                <div id="w_MachineDiv" className="tab-content">
                                    <div className="tab-pane fade active show" id="w_MachineContentDiv">
                                        {tab === "identification" &&
                                            <div>
                                                {!defined &&
                                                    <>
                                                        <TextAssign title="Machine Name" path="machine.name" limit="16" rule={"symbol"} validator={validator} callback={onAssignName} />
                                                        <SelectAssign title="Monte Carlo Simulator" path="machine.simulator.name" items={["mcnp", "openmc"]} rule="text" validator={validator} />
                                                        <SelectAssign title="Source Model" path="machine.model" items={["SSR", "SDEF"]} rule="text" validator={validator} />
                                                    </>
                                                }
                                                {defined &&
                                                    <>
                                                        <TextDisplay title="Machine Name" text={validator.value("machine.name")} />
                                                        <TextDisplay title="Simulator" text={validator.value("machine.simulator.name")} />
                                                        <TextDisplay title="Machine Model" text={validator.value("machine.model")} />
                                                    </>
                                                }
                                            </div>
                                        }
                                        {tab === "description" &&
                                            <TextAssign title="Description" path="machine.description" rule={"text"} multiline="5" validator={validator} />
                                        }
                                        {tab === "files" &&
                                            <div>
                                                <FileUploader title="Materials File" guid={validator.value("machine.machine_id")} tag="materials" item="machine.files.materials" validator={validator} />

                                                {validator.value("machine.simulator.name") === "mcnp" && validator.value("machine.model") === "SDEF" &&
                                                    <FileUploader title="SDEF File" guid={validator.value("machine.machine_id")} tag="sdef" item="machine.files.sdef" validator={validator} />
                                                }

                                                {validator.value("machine.simulator.name") === "openmc" && validator.value("machine.model") === "SDEF" &&
                                                    <div>
                                                        <FileUploader title="Energy Histogram File" guid={validator.value("machine.machine_id")} tag="energy_histogram" item="machine.files.energy_histogram" validator={validator} />
                                                        <FileUploader title="Position Histogram File" guid={validator.value("machine.machine_id")} tag="position_histogram" item="machine.files.position_histogram" validator={validator} />
                                                        <FileUploader title="Custom Source Shared Library" guid={validator.value("machine.machine_id")} tag="shared_library" item="machine.files.shared_library" validator={validator} />
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {tab === "beam" &&
                                            <>
                                                <TextAssign title="Proton Energy (MeV)" path="machine.beam.energy" rule="float" validator={validator} />
                                                <TextAssign title="Proton Current (mA)" path="machine.beam.proton_current" rule="float" validator={validator} />
                                                <TextAssign title="Target Yield (n/s/mA)" path="machine.beam.target_yield" rule="float" validator={validator} />

                                                {validator.value("machine.simulator.name") === "openmc" && validator.value("machine.model") === "SDEF" &&
                                                    <>
                                                        <TextAssign title="Target Z (cm)" path="machine.beam.target_z" rule="float" validator={validator} />
                                                        <TextAssign title="Source Strength" path="machine.beam.source_strength" rule="float" validator={validator} />
                                                        <SelectAssign title="Verbose Logging" path="machine.beam.debug" items={["OFF", "ON"]} rule="bool" validator={validator} />
                                                    </>
                                                }
                                            </>
                                        }
                                        {tab === "settings" &&
                                            <>
                                                {validator.value("machine.simulator.name") === "mcnp" &&
                                                    <>
                                                        <TextDisplay title="Proton Energy (MeV)" text={validator.value("machine.beam.energy")} />
                                                        <TextDisplay title="Proton Current (mA)" text={validator.value("machine.beam.proton_current")} />
                                                        <TextDisplay title="Target Yield (n/s/mA)" text={validator.value("machine.beam.target_yield")} />
                                                    </>
                                                }
                                                {validator.value("machine.simulator.name") === "openmc" &&
                                                    <>
                                                        <TextAssign title="Run Mode" path="machine.simulator.settings.run_mode" rule="text" validator={validator} />
                                                        <TextAssign title="Batches" path="machine.simulator.settings.batches" rule="int" validator={validator} />
                                                        <TextAssign title="Seed" path="machine.simulator.settings.seed" rule="int" validator={validator} />
                                                        <TextAssign title="Electron Treatment" path="machine.simulator.settings.electron_treatment" rule="text" validator={validator} />
                                                        <SelectAssign title="Photon Transport" path="machine.simulator.settings.photon_transport" items={["OFF", "ON"]} rule="bool" validator={validator} />
                                                        <SelectAssign title="Survival Biasing" path="machine.simulator.settings.survival_biasing" items={["OFF", "ON"]} rule="bool" validator={validator} />
                                                        <TextAssign title="Verbosity" path="machine.simulator.settings.verbosity" rule="int" validator={validator} />
                                                        <SelectAssign title="Output Summary" path="machine.simulator.settings.output.summary" items={["NO", "YES"]} rule="bool" validator={validator} />
                                                        <SelectAssign title="Output Tallies" path="machine.simulator.settings.output.tallies" items={["NO", "YES"]} rule="bool" validator={validator} />
                                                        <TextAssign title="Cutoff Weight" path="machine.simulator.settings.cutoff.weight" rule="float" validator={validator} />
                                                        <TextAssign title="Cutoff Weight Average" path="machine.simulator.settings.cutoff.weight_avg" rule="float" validator={validator} />
                                                        <TextAssign title="Cutoff Photon Energy" path="machine.simulator.settings.cutoff.energy_photon" rule="float" validator={validator} />
                                                        <TextAssign title="Temperature" path="machine.simulator.settings.temperature.default" rule="float" validator={validator} />
                                                    </>
                                                }
                                                <TextAssign title="Default Particle Count" path="machine.simulator.settings.particle_count" rule="float" validator={validator} />
                                            </>
                                        }
                                        {tab === "collimators" &&
                                            <TableWidget data={generateCollimatorTable()} buttonData={buttonData} />
                                        }
                                        {tab === "pending" &&
                                            <TableWidget data={generatePendingItemsTable()} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div >
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={onClose}>Close</button>
                    {
                        allowSubmit() &&
                        <button type="button" className="btn btn-primary" onClick={onSubmit}>{submitButtonText()}</button>
                    }
                    {
                        allowDefine() &&
                        <button type="button" className="btn btn-primary" onClick={onDefine}>Define</button>
                    }
                </Modal.Footer>
            </Modal >
            {
                collimatorValidator &&
                <CollimatorDialog machine={validator.value("machine.name")} machine_id={validator.value("machine.machine_id")} model={validator.value("machine.model")} simulator={validator.value("machine.simulator.name")} slot={collimatorValidator.value("self.slot")} validator={collimatorValidator} onName={onNameCollimator} onSave={onSaveCollimator} onClose={onCloseCollimatorDialog} />
            }
        </div >
    );
}

export default MachineDialog;