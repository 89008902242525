import { useState, useEffect } from 'react'

import gSession from '../script/session';
import gUtilities from '../script/utilities';
import TableWidget from './TableWidget';
import gEventRouter from "../script/event_router";
import gDataStore from "../script/data_store";

const JobPage = (props) => {
    let empty = { header: [], columns: [], rows: [] };
    const [jobs, setJobs] = useState(null);
    const [data, setData] = useState(empty);
    const [current, setCurrent] = useState(null);

    if (!current) {
        let last = gDataStore.retrieve("CurrentJob", null);

        if (last) {
            setCurrent(last);
        }
    }

    function enableDetailsPages(job) {
        let record;
        if (job && job.job_id && jobs[job.job_id]) {
            record = jobs[job.job_id];
            setCurrent(record);

            gDataStore.store("CurrentJob", record);
        }
    }

    function plan(job) {
        gEventRouter.invoke("PlanDialog.showModal", job);
    }

    function view(job) {
        gEventRouter.invoke("JobDialog.showModal", job);
    }

    function jobButtonStyle(buttonData, job) {
        let style;

        if (gSession.isInGroup("job")) {
            style = { visiblity: "visible" };
        } else {
            style = { visibility: "hidden" };
        }

        return style;
    }

    // prepend the first 8 chars of the job_id to each row
    const addFingerprints = (data) => {
        let index = gUtilities.tableColumnIndex(data, "job_id");
        let row;
        let job_id;
        let fingerprint;
        for (let i in data.rows) {
            row = data.rows[i];
            job_id = row[index];
            fingerprint = gUtilities.fingerprint(job_id);
            row.unshift(fingerprint);
        }

        data.header.unshift("Fingerprint");
        data.columns.unshift("fingerprint");
    }

    let buttonData = {
        buttons: [
            {
                label: "View",
                callback: view,
                style: jobButtonStyle
            },
            {
                label: "Plan",
                callback: plan
            },
            {
                label: "Select",
                callback: enableDetailsPages,
                style: jobButtonStyle
            }
        ]
    };

    async function update() {
        let request = {
            service: "job.jobList",
            query: { "$and": [{ version: "0.2" }, { "$or": [{ archive: { "$exists": false } }, { "archive.type": "live" }] }] },
            projection: {
                _id: 0,    // NOTE: only _id can be 0 if others are 1
                version: 1,
                job_id: 1,
                datetime: 1,
                state: 1,
                progress: 1,
                converged: 1,
                duration_min: 1,
                tag: 1,
                "machine.name": 1,
                "machine.simulator.name": 1,
                "plan.plan_name": 1,
                "plan.nbeams": 1
            }
        };

        let response = await gSession.exchange(request);

        if (!isMounted()) {
            return;
        }

        let sorted;
        let records = {};
        if (response.isError) {
            gUtilities.showError("Job List Update", response.message);
            return;
        } else {
            for (let record of response) {
                records[record.job_id] = record;
            }
            setJobs(records);

            // latest datetime at top of table
            sorted = response.sort((l, r) => {
                if (l.datetime < r.datetime) {
                    return 1;
                } else if (l.datetime > r.datetime) {
                    return -1;
                }

                return 0;
            });
        }

        if (sorted.length > 0) {
            hoistNestedData(sorted);
        }

        let tableData = gUtilities.convertDbResultToTableData(sorted);

        gUtilities.hideTableColumn(tableData, "authorizedToRun");
        gUtilities.hideTableColumn(tableData, "datetime");
        gUtilities.hideTableColumn(tableData, "version");

        gUtilities.setTableHeader(tableData, "simulator_name", "Simulator");
        gUtilities.setTableHeader(tableData, "machine_name", "Machine");
        gUtilities.setTableHeader(tableData, "duration_min", "Time (m)");
        gUtilities.setTableHeader(tableData, "plan_name", "Plan");
        gUtilities.setTableHeader(tableData, "converged", "Converged");
        gUtilities.setTableHeader(tableData, "progress", "Progress");
        gUtilities.setTableHeader(tableData, "state", "State");
        gUtilities.setTableHeader(tableData, "tag", "Tag");

        addFingerprints(tableData);

        setData(tableData);
    }

    // convert plan object to plan_name string
    function hoistNestedData(records) {
        for (let record of records) {
            record.plan_name = record.plan.plan_name;
            record.nbeams = record.plan.nbeams;
            delete record.plan;

            if (record.version === "0.2") {
                record.simulator_name = record.machine.simulator.name;
            } else {
                record.simulator_name = "mcnp"; // romove once OpenMC is released
                delete record.simulator;
            }

            record.machine_name = record.machine.name;
            delete record.machine;
        }
    }

    function scheduleUpdate() {
        let id = setTimeout(() => {
            try {
                update();
            } catch (ex) {
                console.log("ERROR: [EXCEPTION] jobs update - ", ex);
            }
        }, 500);

        return id;
    }

    function rowClass(row) {
        if (current && row[1] === current.job_id) {
            return "table-success";
        } else {
            return "table-info";
        }
    };

    // detect existance of this page's root <div>
    function isMounted() {
        return gUtilities.isMounted("w_JobPageRootDiv");
    }

    useEffect(() => {
        let timer_id = scheduleUpdate();

        // cleanup function: stop updates.
        return () => {
            if (!isMounted()) {
                clearTimeout(timer_id);
            }
        };
    }, [data]);

    return (
        <div id="w_JobPageRootDiv">
            <TableWidget data={data} buttonData={buttonData} rowClassFunction={rowClass} />
        </div>
    );
}

export default JobPage;