import 'bootstrap/dist/js/bootstrap.min.js';
import './style/spacelab.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';

import Header from './components/Header';
import JobPage from './components/JobPage';
import DvhPage from './components/DvhPage';
import LogPage from './components/LogPage';
import HomePage from './components/HomePage';
import AboutPage from './components/AboutPage';
import AdminPage from './components/AdminPage'
import JobDialog from './components/JobDialog';
import PlanDialog from './components/PlanDialog';
import LogInDialog from './components/LogInDialog';
import ErrorDialog from './components/ErrorDialog';
import ClusterPage from './components/ClusterPage';
import JobFilePage from './components/JobFilePage';
import JobPlotPage from './components/JobPlotPage';
import DatabasePage from './components/DatabasePage';
import JobReportPage from './components/JobReport';
import DirectiveDialog from './components/DirectiveDialog';
import DocumentationPage from './components/DocumentationPage';
import CommissioningPage from './components/CommissioningPage';
import ConfirmationDialog from './components/ConfirmationDialog';
import JobFileUploadDialog from './components/JobFileUploadDialog';

import gUtilities from './script/utilities';
import FileUploadDialog from './components/FileUploadDialog';

function App() {
  useEffect(() => {
    gUtilities.verifyUrl();
  }, []);

  return (
    <div className="container">
      <Router>
        <header>
          <Header />
        </header>
        <JobDialog />
        <PlanDialog />
        <LogInDialog />
        <ErrorDialog />
        <FileUploadDialog />
        <JobFileUploadDialog />
        <DirectiveDialog />
        <ConfirmationDialog />
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/app/about" element={<AboutPage />} />
          <Route exact path="/app/admin" element={<AdminPage />} />
          <Route exact path="/app/jobs" element={<JobPage />} />
          <Route exact path="/app/job/log" element={<LogPage />} />
          <Route exact path="/app/job/dvh" element={<DvhPage />} />
          <Route exact path="/app/job/file" element={<JobFilePage />} />
          <Route exact path="/app/job/plot" element={<JobPlotPage />} />
          <Route exact path="/app/job/report" element={<JobReportPage />} />
          <Route exact path="/app/database" element={<DatabasePage />} />
          <Route exact path="/app/cluster" element={<ClusterPage />} />
          <Route exact path="/app/documentation" element={<DocumentationPage />} />
          <Route exact path="/app/commissioning" element={<CommissioningPage />} />
        </Routes>
      </Router>
    </div >
  );
}

export default App;
