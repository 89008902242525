//
// Log in dialog.
//
// https://www.pluralsight.com/guides/working-with-bootstraps-modals-react
//
import 'bootstrap/dist/js/bootstrap.min.js';
import Modal from "react-bootstrap/Modal";
import { useState } from 'react';
import gEventRouter from "../script/event_router.js";
import gUtilities from '../script/utilities';
import gSession from '../script/session.js';
import gDataStore from '../script/data_store.js';

const LogInDialog = () => {
    const [isOpen, setIsOpen] = useState(false);

    const showModal = () => {
        setIsOpen(true);
    };

    const hideModal = () => {
        setIsOpen(false);
    };

    async function login() {
        let username = document.querySelector("#w_LogInUsername").value;
        let password = document.querySelector("#w_LogInPassword").value;

        let response = await gSession.login(username, password);

        if (response.isError) {
            gUtilities.showError("Login", response.message);
        } else {
            gDataStore.store("user.loggedIn", true);
            setIsOpen(false);
        }
    };

    gEventRouter.register("LogInDialog.showModal", showModal);

    return (
        <div>
            <Modal show={isOpen}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="w_LogInHeader">Log Into Dose Engine</h5>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label className="col-form-label mt-4" htmlFor="w_LogInUsername">Username</label>
                            <input type="text" className="form-control" placeholder="username" id="w_LogInUsername"></input>
                        </div><div className="form-group">
                            <label className="col-form-label mt-4" htmlFor="w_LogInPassword">Password</label>
                            <input type="password" className="form-control" placeholder="" id="w_LogInPassword"></input>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={hideModal}>Close</button>
                        <button type="button" className="btn btn-primary" onClick={login}>Login</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default LogInDialog;