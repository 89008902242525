//
// File Upload dialog.
//
import 'bootstrap/dist/js/bootstrap.min.js';
import Modal from "react-bootstrap/Modal";
import { useState } from 'react';

import gUtilities from '../script/utilities';
import gDataStore from '../script/data_store.js';
import gEventRouter from "../script/event_router.js";

//
// Usage:
//   In JSX: <FileUploadDialog/>
//   In code: gEventRouter.invoke("FileUploadDialog.showModal", data);
//
//   See showModal() below for data contents.
//
const FileUploadDialog = () => {
    const [data, setData] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    //
    // data:
    //   string   path     - api path for URI e.g. /system/upload
    //   string   folder   - dsv path (names separated by dots)
    //   function callback - callback to receieve response
    //
    // response:
    //   isError, message - error message
    //      OR
    //   string path      - path to file on server
    //
    const showModal = (data) => {
        setData(data);
        setIsUploading(false);
        setSelectedFile(null);
        setIsOpen(true);
    };

    const hideModal = () => {
        gDataStore.store("IsSimulatedFileUploadInProgress", false);
        setIsOpen(false);
    };

    function updateProgress(value) {
        let widget = document.querySelector('#w_FileUploadProgress');
        if (widget) {
            widget.style.width = value.toString() + "%";
        }
    }

    function startSimulatedProgress() {
        // For some reason storing this in a useState() doesn't update!
        gDataStore.store("IsSimulatedFileUploadInProgress", true);

        simulateProgress(0);
    }

    function stopSimulatedProgress() {
        gDataStore.store("IsSimulatedFileUploadInProgress", false);
    }

    function simulateProgress(count) {
        setTimeout(() => {
            updateProgress(count);

            if (gDataStore.retrieve("IsSimulatedFileUploadInProgress") && count < 100) {
                simulateProgress(count + 1);
            }
        }, 1000);
    }

    async function onUpload() {
        let values = data;

        if (!selectedFile) {
            gUtilities.showError("Source File", "Source file must be uploaded");
            return;
        }

        setIsUploading(true);
        let response = { notFound: true };
        if (data.existsCallback) {
            startSimulatedProgress();
            response = await data.existsCallback(data, selectedFile);
            stopSimulatedProgress();
        }

        if (response.notFound) {
            response = await gUtilities.uploadFile(data.path, data.folder, selectedFile, updateProgress);
        }

        if (response.isError) {
            gUtilities.showError("Upload File", "Upload failed: " + response.message);
            values = response;
        } else {
            values.path = response.path;
            console.log("Upload", data.path, values.path);
        }

        setIsUploading(false);

        try {
            data.callback(values);
        } catch (ex) {
            gUtilities.showError("Upload File", "Upload failed: " + ex.toString());
        }

        hideModal();
    }

    const onBrowse = (event) => {
        const widget = document.createElement("input");

        widget.setAttribute(
            "type",
            "file"
        );

        widget.setAttribute(
            "name",
            "file"
        );

        widget.addEventListener(
            "change",
            (event) => {
                onFileSelected(event.target.files[0]);
            }
        );

        // popup the file browser dialog
        widget.click();
    };

    async function onFileSelected(fdata) {
        let widget = document.querySelector("#w_FileUploadPathText");
        widget.value = fdata.name;

        widget = document.querySelector("#w_FileSizeInput");
        widget.value = fdata.size;

        setSelectedFile(fdata);
    }

    gEventRouter.register("FileUploadDialog.showModal", showModal);

    return (
        <div id="w_FileUploadDialog">
            <Modal show={isOpen}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="w_FileUploadDialogHeader">Upload File</h5>
                    </div>
                    <div className="modal-body  alert alert-dismissible alert-info" >
                        <div className="form-group">
                            <label className="col-form-label mt-1" htmlFor="w_FileUploadPathText">File Path</label>
                            <div className="input-group mb-1">
                                <input type="text" className="form-control" id="w_FileUploadPathText" placeholder="Browse to upload" aria-label="File Path" aria-describedby="w_FileBrowseButton" value={data.tag} readOnly={true}></input>
                                <button className="btn btn-primary" type="button" id="w_FileBrowseButton" onClick={onBrowse}>Browse</button>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-form-label mt-1" htmlFor="w_FileSizeInput">File Size</label>
                            <input type="text" className="form-control" placeholder="" id="w_FileSizeInput" readOnly={true}></input>
                        </div>
                        {isUploading &&
                            <div className="form-group">
                                <label className="col-form-label mt-1" htmlFor="w_FileUploadProgress">Upload Progress</label>
                                <div className="progress">
                                    <div className="progress-bar progress-bar-striped bg-success" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" value="0" id="w_FileUploadProgress"></div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={hideModal}>Close</button>
                        {!isUploading && selectedFile &&
                            <button type="button" className="btn btn-primary" onClick={onUpload}>Upload</button>
                        }
                    </div>
                </div>
            </Modal >
        </div >
    );
}

export default FileUploadDialog;