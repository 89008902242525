//
// Select input with validation.
//
import { useState } from 'react';
import gUtilities from '../script/utilities';

//
// props:
//   string    title      - label for item
//   string    path       - route to value in Validator; e.g. "machine.settings.energy"
//   string    rule       - "text" or "bool"; if "bool" then items=[ "false-text", "true-text"]
//   [string]  items      - array of option name strings
//   Validator validator  - class Validator
//   function  callback   - optional change callback
//
const SelectAssign = (props) => {
    const [refreshed, setRefreshed] = useState(0);
    const [widgetID] = useState(gUtilities.widgetID());

    function validate(value) {
        props.validator.validate(props.path, value);
        setRefreshed(refreshed + 1);
    }

    function onChange(evt) {
        let value = evt.target.value;

        if (props.rule === "bool") {
            if (props.items.length === 2) {
                value = value === props.items[1];
            } else {
                gUtilities.showError("Select", "Invalid Boolean item set");
                return;
            }
        }

        validate(value);
    }

    function onAssign() {
        let widget = document.getElementById(widgetID);
        let value = widget.value;
        let evt = { target: {} };

        if (props.items.includes(value)) {
            evt.target.value = value;
            onChange(evt);
        } else {
            gUtilities.showError("Select", "Please select a valid value");
        }
    }

    function value() {
        let text;

        if (props.validator.value(props.path) !== null) {
            if (props.rule === "bool") {
                text = props.validator.value(props.path) ? props.items[1] : props.items[0];
            } else {
                text = props.validator.value(props.path);
            }
        } else {
            text = "Select";
        }

        return text;
    }

    function buttonText() {
        let text;

        if (props.validator.query(props.path)) {
            text = "Valid";
        } else if (value() === "Select") {
            text = "Select";
        } else {
            text = "Assign"
        }

        return text;
    }

    return (
        <div className="form-group">
            <label htmlFor={widgetID} className="form-label mt-2">{props.title}</label>
            <div className="input-group mb-1">
                <select className="form-select" id={widgetID} value={value()} onChange={onChange}>
                    {props.validator.value(props.path) === null &&
                        <option>Select</option>
                    }
                    {props.items.map((item) => (
                        <option>{item}</option>
                    ))
                    }
                </select>
                <button className="btn btn-primary" type="button" id={widgetID + "Button"} onClick={onAssign}>{buttonText()}</button>
            </div>
        </div >
    );
}

export default SelectAssign;