//
// Error dialog.
//
// https://www.pluralsight.com/guides/working-with-bootstraps-modals-react
//
import 'bootstrap/dist/js/bootstrap.min.js';
import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from 'react';
import gEventRouter from "../script/event_router.js";

const ErrorDialog = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [details, setDetails] = useState(null);

    const showModal = (data) => {
        if (!data) {
            data.operation = "Unknown operation";
            data.message = "Unknown error";
        }

        setDetails(data);
        setIsOpen(true);
    };

    const hideModal = () => {
        setIsOpen(false);
    };

    gEventRouter.register("ErrorDialog.showModal", showModal);

    useEffect(() => {
        if (isOpen) {
            document.querySelector("#w_ErrorOperation").value = details.operation;
            document.querySelector("#w_ErrorMessage").value = details.message;
        }
    }, [isOpen, details]);

    return (
        <div>
            <Modal show={isOpen}>
                <div className="modal-content">
                    <div className="modal-header text-danger">
                        <h5 className="modal-title text-danger">Error</h5>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label className="col-form-label mt-4" htmlFor="w_ErrorOperation">Operation</label>
                            <input type="text" className="form-control" id="w_ErrorOperation"></input>
                        </div>
                        <div className="form-group has-danger">
                            <label className="form-label mt-4" htmlFor="w_ErrorMessage">Message</label>
                            <input type="text" value="wrong value" className="form-control is-invalid" id="w_ErrorMessage"></input>
                            <div className="invalid-feedback" id="w_ErrorFootnote">The operation failed</div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={hideModal}>Close</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default ErrorDialog;