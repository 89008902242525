import { useState, useEffect } from 'react'

import gSession from '../script/session';
import gUtilities from '../script/utilities';
import gDataStore from "../script/data_store.js";
import gEventRouter from "../script/event_router.js";

import TableWidget from './TableWidget';

const k_max_view_size = 7 * 1024 * 1024;   // API Gateway max payload is 10 MB; Base64 expands to 7 * (4/3)

const JobFilePage = () => {
    const empty = gUtilities.convertDbResultToTableData([]);
    const [job, setJob] = useState(null);
    const [data, setData] = useState(empty);
    const [banner, setBanner] = useState(empty);
    const [refresh, setRefresh] = useState(true);

    let currentJob = gDataStore.retrieve("CurrentJob", null);
    if (!job) {
        setJob(currentJob);
        setRefresh(true);
        updateBanner(currentJob);
    } else if (currentJob && currentJob.job_id !== job.job_id) {
        setJob(currentJob);
        setRefresh(true);
        updateBanner(currentJob);
    }

    function onCurrentJob(currentJob) {
        setJob(currentJob);
        setRefresh(true);

        updateBanner(currentJob);
    }
    gDataStore.onStore("CurrentJob", onCurrentJob);

    function updateBanner(job) {
        setBanner({
            header: ["Fingerprint", "Plan Name", "Job State", "Machine Name", "Simulator"],
            columns: ["fingerprint", "plan_name", "state", "machine_name", "simulator_name"],
            rows: [
                [gUtilities.fingerprint(job.job_id), job.plan_name, job.state, job.machine_name, job.simulator_name]
            ]
        });
    }

    // 
    // Create URL for file access
    //   filname - name of file
    //   op      - on of { download, view }
    //   return  - url
    //
    function getUrl(filename, op) {
        let host = document.location.host;
        let protocol = document.location.protocol;

        const url = `${protocol}//${host}/job/${op}?job_id=${job.job_id}&filename=${filename}`;

        return url;
    }

    function convertToTableData(response) {
        let rows = response.map(data => {
            let size = 1.0 * data.size;
            let unit = "B";
            if (size > 1e9) {
                size /= 1e9;
                unit = "GB"
            } else if (size > 1e6) {
                size /= 1e6;
                unit = "MB";
            } else if (size > 1e3) {
                size /= 1e3;
                unit = "KB";
            }

            data = {
                job_id: job.job_id,
                filename: data.filename,
                size: "N/A"
            };

            if (job.simulator_name === "mcnp") {
                data.size = `${size} ${unit}`;
            }

            return data;
        });

        return gUtilities.convertDbResultToTableData(rows);
    }

    const load = async () => {
        let request = {
            service: "job.fileResult",
            job_id: job.job_id
        };

        let tableData;
        let response;
        try {
            response = await gSession.exchange(request);
            if (response.isError) {
                gUtilities.showError("Job File List", response.message);
            } else {
                tableData = convertToTableData(response);
                setData(tableData);
            }
        } catch (ex) {
            gUtilities.showError("Job File List", ex.toString());
        }
    }

    useEffect(() => {
        setRefresh(false);
        if (refresh && job) {
            load();
        }

    }, [refresh]);

    const download = async (fdata) => {
        const filename = fdata.filename

        let url = getUrl(filename, "download");

        const widget = document.createElement('a');
        widget.href = url;
        widget.setAttribute(
            "download",
            filename
        );

        document.body.appendChild(widget);

        widget.click();

        widget.parentNode.removeChild(widget);
    }

    async function getOpenMcPlotMetadata(data) {
        let request = {
            service: "file.json",
            job_id: job.job_id,
            filename: data.filename.substring(0, data.filename.length - 4) + ".json"
        };

        let response = await gSession.exchange(request);
        if (response.isError) {
            response.origin = "???";
            response.basis = "???";
        }

        return response;
    }

    async function view(data) {
        let request = {
            service: "file.contents",
            job_id: job.job_id,
            filename: data.filename
        };

        let response = await gSession.exchange(request);

        if (response.isError) {
            gUtilities.showError("View File", response.message);
            return;
        }

        let url;
        if (currentJob.simulator_name === "openmc" && data.filename.endsWith(".png")) {
            let metadata = await getOpenMcPlotMetadata(data);

            console.log("DEBUG: PLOT");
            let html = `
            <html>
                <body style="background: black; color: white;">
                    <div style="margin: auto; width: 80%; display: flex; padding-top: 20px">
                        <div style="padding: 10px">
                            <table>
                                <tr><td>DCS JOB ID</td><td>${job.job_id}</td></tr>
                                <tr><td>PLOT NAME</td><td>${data.filename}</td></tr>
                                <tr><td>PLOT BASIS</td><td>${metadata.basis}</td></tr>
                                <tr><td>PLOT ORIGIN</td><td>${metadata.origin}</td></tr>
                            </table>
                            </div>
                            <div>
                                <img src="data:image/png;charset=utf-8;base64,${response.contents}" alt="Red dot" style="width: 400; height: 400;"/>
                            </div>
                            <p></p>
                        </div>
                    </div>
                </body>
            </html>`;

            url = URL.createObjectURL(
                new Blob([html], { type: "text/html" })
            );
        } else {
            console.log("DEBUG: CONTENTS");
            let buffer = atob(response.contents);
            let text = buffer.toString("utf-8");

            if (data.filename.endsWith(".json")) {
                let obj = JSON.parse(text);
                text = JSON.stringify(obj, null, 4);
            }

            url = URL.createObjectURL(
                new Blob([text], { type: "text/plaintext" })
            );
        }


        window.open(url, "_blank", "noopener noreferrer");
    }

    function fileViewStyle(buttonData, data) {
        let style;
        let filename = data.filename;

        let isViewable = filename.endsWith(".sh") ||
            filename.endsWith(".log") ||
            filename.endsWith(".txt") ||
            filename.endsWith(".inp") ||
            filename.endsWith(".csv") ||
            filename.endsWith(".pdf") ||
            filename.endsWith(".xml") ||
            filename.endsWith(".jpg") ||
            filename.endsWith(".png") ||
            filename.endsWith(".json") ||
            filename.endsWith(".plotm");

        if (isViewable) {
            style = { visiblity: "visible" };
        } else {
            style = { visibility: "hidden" };
        }

        return style;
    }

    function fileDownloadStyle(buttonData, data) {
        let style;

        if (job.simulator_name === "mcnp") {
            style = { visiblity: "visible" };
        } else {
            style = { visibility: "hidden" };
        }

        return style;
    }

    let buttonData = {
        buttons: [
            {
                label: "View",
                callback: view,
                style: fileViewStyle
            },
            {
                label: "Download",
                callback: download,
                style: fileDownloadStyle
            }
        ]
    };

    let bannerButtons = {
        buttons: [
            {
                label: "Upload",
                callback: () => {
                    gEventRouter.invoke("JobFileUploadDialog.showModal", job);
                }
            }
        ]
    };

    return (
        <div>
            <div className="alert alert-dismissible alert-warning">
                <h4 className="alert-heading">Job Files</h4>
                <TableWidget data={banner} buttonData={bannerButtons} />
            </div>
            <div>
                <TableWidget data={data} buttonData={buttonData} />
            </div>
        </div>
    );
}

export default JobFilePage;