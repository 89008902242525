//
// Input text with validation based on regular expression and/or callback.
//
import { useState } from 'react';
import gUtilities from '../script/utilities';

//
// props:
//   string    title      - label for item
//   string    path       - route to value in Validator; e.g. "machine.settings.energy"
//   string    rule       - one of { readonly, symbol, text, int, float, regex }
//   string    regex      - regular expression e.g. "^[A-Z][a-z]*$" = word beginning with capital letter
//   string    warning    - optional message for input error
//   int       limit      - optional length limit
//   Validator validator  - class Validator
//   function  callback   - optional async returning true if value accepted
//
// where rule:
//   readonly - value cannot be edited
//   symbol   - programming language identifier; /^\w+$/
//   text     - any characters
//   int      - integer
//   float    - real number, e.g. 3  8.7 -17 4.63e-2
//   regex    - props must contain 'regex' and 'warning' for warning
//
let kRules = {
    "readonly": "",
    "symbol": "^\\w+$",
    "text": ".*",
    "int": "^[0-9]+",
    "float": "^[+-]?[0-9]+([.][0-9]+)?([eE][+-]?[0-9]+)?$"
};

let kWarnings = {
    "readonly": "This field cannot be changed",
    "symbol": "Input must contain only: A-Z a-z 0-9 _ ",
    "text": "Any characters allowed",
    "int": "Input must be digits only",
    "float": "Input must be a real number (including exponent)",
    "regex": "See manual for valid input specification"
};

function expression(props) {
    let exp;
    if (kRules.hasOwnProperty(props.rule)) {
        exp = kRules[props.rule];
    } else if (props.hasOwnProperty("regex")) {
        exp = props.regex;
    } else {
        gUtilities.showError("Text Assign Widget", "Rule regex has no regex value");
        exp = kRules["text"];
    }

    return exp;
}

function warningMessage(props) {
    let msg = "";
    if (props.hasOwnProperty("warning")) {
        msg = props.warning;
    }

    msg = kWarnings[props.rule];

    if (props.hasOwnProperty("limit")) {
        msg += `; length limited to ${props.limit} characters`;
    }

    return msg;
}

function lengthLimit(props) {
    if (props.hasOwnProperty("limit")) {
        return parseInt(props.limit);
    }

    return 1000;
}

const TextAssign = (props) => {
    let val = props.validator.value(props.path);
    if (!val) {
        val = props.rule;
    }

    const [value, setValue] = useState(val);
    const [refresh, setRefresh] = useState(0);
    const [warning, setWarning] = useState(false);
    const [widgetID] = useState(gUtilities.widgetID());

    function invalidate() {
        props.validator.invalidate(props.path);
        setValue(props.rule);
        setRefresh(refresh + 1);
    }

    function validate(newValue) {
        if (props.rule === "int") {
            newValue = parseInt(newValue);
        } else if (props.rule === "float") {
            newValue = parseFloat(newValue);
        }

        console.log("DEBUG: TextAssign validate", props.path, newValue);

        props.validator.validate(props.path, newValue);
        setValue(newValue);
        setRefresh(refresh + 1);
    }

    function onChange(evt) {
        let haveWarning = false;

        if (props.rule === "readonly") {
            return;
        }

        let value = evt.target.value;

        invalidate();

        if (value.length > lengthLimit(props)) {
            haveWarning = true;
            value = value.substr(0, 16);
        }

        if (!value.match(expression(props)) && value !== "") {
            haveWarning = true;
        }

        setValue(value);
        setWarning(haveWarning);

        if (props.multiline) {
            evaluate(value);
        }
    }

    async function onAssign() {
        evaluate(value);
    }

    async function evaluate(text) {
        if (!text.match(expression(props))) {
            invalidate();
            setWarning(true);
        } else {
            setWarning(false);
            if (props.callback) {
                try {
                    let result = await props.callback(text);
                    if (result) {
                        validate(text);
                    } else {
                        invalidate();
                    }
                } catch (ex) {
                    gUtilities.showError("Assign Text", "Callback handler failed: " + ex.toString());
                    invalidate(props.path);
                }
            } else {
                validate(text);
            }
        }
    }

    function onWarningDismiss() {
        setWarning(false);
    }

    return (
        <div className="form-group">
            <label className="col-form-label mt-1" htmlFor={widgetID + "Text"}>{props.title}</label>
            <div className="input-group mb-1">
                {props.multiline &&
                    <textarea className="form-control ml-4" id={widgetID + "Text"} rows={props.multiline} value={value.toString()} onChange={onChange}></textarea>
                }
                {!props.multiline &&
                    <>
                        <input type="text" className="form-control" id={widgetID + "Text"} aria-label="Materials File Path" aria-describedby={widgetID + "Button"} value={value} onChange={onChange}></input>
                        <button className="btn btn-primary" type="button" id={widgetID + "Button"} onClick={onAssign}>{props.validator.query(props.path) ? "Valid" : "Assign"}</button>
                    </>
                }
            </div>
            {
                warning &&
                <div className="alert alert-dismissible alert-warning">
                    <button type="button" className="btn-close" onClick={onWarningDismiss}></button>
                    <h4 className="alert-heading">Warning!</h4>
                    <p className="mb-0">{warningMessage(props)}</p>
                </div>
            }
        </div >
    );
}

export default TextAssign;