//
// Collimator editor dialog.
//
import 'bootstrap/dist/js/bootstrap.min.js';
import Modal from "react-bootstrap/Modal";
import { useState } from 'react';

import gUtilities from '../script/utilities';
import TextAssign from './TextAssign';
import FileUploader from './FileUploader';

const CollimatorDialog = (props) => {
    const [isOpen, setIsOpen] = useState(true);
    const [refresh, setRefresh] = useState(null);

    if (refresh === null) {
        props.validator.pushCallback(setRefresh);
        setRefresh({});
    }

    const hideModal = () => {
        setIsOpen(false);
        setRefresh(null);

        props.validator.popCallback();

        if (props.onClose) {
            props.onClose();
        }
    };

    async function onSave() {
        props.validator.validate("self.status", "VALID");

        props.onSave();

        hideModal();
    }

    async function onBlank() {
        let list = props.validator.list();

        for (let item of list) {
            props.validator.validate(item, "N/A");
        }

        props.validator.validate("self.name", "BLANK");
        props.validator.validate("self.slot", props.slot);
        props.validator.validate("self.status", "VOID");

        hideModal();
    }

    function onClose() {
        hideModal();
    }

    function allowSave() {
        return props.validator.allValid() && props.validator.value("self.status") === "NAME";
    }

    function onAssignName(name) {
        let allowed = false;

        if (props.onName(name)) {
            props.validator.validate("self.status", "NAME");
            allowed = true;
        } else {
            gUtilities.showError("Assign Name", "Name must be unique");
        }

        return allowed;
    }

    /*
    async function checkFileCache(data, file) {
        let request = {
            service: "system.cache.upload",
            id: data.folder,
            filename: file.name,
            size: file.size
        };

        let response = await gSession.exchange(request);

        if (response.isError) {
            gUtilities.showError("Check File Cache", response.message);
        }

        return response;
    }
    */

    return (
        <div id="w_CollimatorDialog">
            <Modal show={isOpen}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="w_CollimatorEditorHeader">Collimator Data Editor</h5>
                    </div>
                    <div className="modal-body">
                        <TextAssign title="Name" path={`self.name`} limit="16" rule={"symbol"} validator={props.validator} callback={onAssignName} />
                        <TextAssign title="FWHM (cm)" path={`self.size`} rule="float" validator={props.validator} />
                        <TextAssign title="Offset (cm)" path={`self.offset`} rule="float" validator={props.validator} />

                        {props.model === "SSR" &&
                            <>
                                <TextAssign title="Input Particle Count" path={`self.ssr_input_particles`} rule="float" validator={props.validator} />
                                <TextAssign title="Output Particle Count" path={`self.ssr_output_particles`} rule="float" validator={props.validator} />
                                <FileUploader title="RSSA File" guid={props.machine_id} tag="rssa" item="self.files.rssa" validator={props.validator} />
                            </>
                        }

                        {props.simulator === "mcnp" &&
                            <>
                                <FileUploader title="Surfaces File" guid={props.machine_id} tag="surfaces" item="self.files.surfaces" validator={props.validator} />
                                <FileUploader title="Cells File" guid={props.machine_id} tag="cells" item="self.files.cells" validator={props.validator} />
                            </>
                        }
                        {props.simulator === "openmc" &&
                            <>
                                <FileUploader title="Geometry File" guid={props.machine_id} tag="geometry" item="self.files.geometry" validator={props.validator} />
                            </>
                        }
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={onClose}>Close</button>
                        {allowSave() &&
                            <button type="button" className="btn btn-primary" onClick={onSave}>Save</button>
                        }
                        {!props.validator.allValid() &&
                            <button type="button" className="btn btn-primary" onClick={onBlank}>Blank</button>
                        }
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default CollimatorDialog;