//
// Log in dialog.
//
// https://www.pluralsight.com/guides/working-with-bootstraps-modals-react
//
import 'bootstrap/dist/js/bootstrap.min.js';
import Modal from "react-bootstrap/Modal";
import { useState } from 'react';

import gUtilities from '../script/utilities.js';

const DatabaseDialog = (params) => {
    const [isOpen, setIsOpen] = useState(true);

    const hideModal = () => {
        setIsOpen(false);
    };

    function onSubmit() {
        let collection = document.querySelector("#w_CollectionSelect").value;
        let squery = document.querySelector("#w_QueryEditTextarea").value;
        let sprojection = document.querySelector("#w_ProjectionTextarea").value;

        let query;
        try {
            query = JSON.parse(squery);
        } catch (ex) {
            gUtilities.showError("Query Expression", ex.toString());
            return;
        }

        let projection;
        try {
            projection = JSON.parse(sprojection);
        } catch (ex) {
            gUtilities.showError("Projection Expression", ex.toString());
            return;
        }

        let statement = {
            collection: collection,
            query: query,
            projection: projection
        }

        params.onSubmit(statement);

        hideModal();
    }

    return (
        <div>
            <Modal show={isOpen}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="w_QueryEditDialogHeader">Edit Query</h5>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label className="col-form-label mt-4" htmlFor="w_CollectionSelect">Collection</label>
                            <select type="text" className="form-control" placeholder="{}" id="w_CollectionSelect" defaultValue={"Select"}>
                                <option>Select</option>
                                <option>configuration</option>
                                <option>machine</option>
                                <option>job</option>
                                <option>plan</option>
                                <option>user</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label className="col-form-label mt-4" htmlFor="w_QueryEditTextarea">Query (JSON)</label>
                            <textarea className="form-control" id="w_QueryEditTextarea" rows="1" defaultValue={"{}"}></textarea>
                        </div>
                        <div className="form-group">
                            <label className="col-form-label mt-4" htmlFor="w_ProjectionTextarea">Projection (JSON)</label>
                            <textarea className="form-control" id="w_ProjectionTextarea" rows="1" defaultValue={"{}"}></textarea>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={hideModal}>Close</button>
                        <button type="button" className="btn btn-primary" onClick={onSubmit}>Submit</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default DatabaseDialog;