import { useEffect, useState } from 'react';
import 'bootstrap/dist/js/bootstrap.min.js';
import Modal from "react-bootstrap/Modal";
import gEventRouter from "../script/event_router.js";
import gUtilities from '../script/utilities';
import gSession from '../script/session';

function PlanDialog() {
    const [job, setJob] = useState();
    const [jobId, setJobId] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const showModal = async (job) => {
        let id = job.job_id;

        setJobId(id);
        setJob(null);
        setIsOpen(true);
    };

    const hideModal = () => {
        setIsOpen(false);
    };

    gEventRouter.register("PlanDialog.showModal", showModal);

    async function fetchJob(job_id) {
        let request = {
            service: "job.data",
            job_id: job_id
        };

        let response = await gSession.exchange(request);

        if (response.isError) {
            gUtilities.showError("Job Data", response.message);
        }

        if (response.job_id === job_id) {
            setJob(response);
        }
    }

    async function directives() {
        if (gUtilities.leafTrue(job, "directives.ON.on")) {
            gEventRouter.invoke("DirectiveDialog.showModal", job);
        } else {
            gUtilities.showError("Directives", "Directives disabled - check TLS,on POI in plan");
        }
    }

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        if (!job) {
            fetchJob(jobId);
        } else {
            let widget = document.querySelector("#w_PlanJobIdField");
            if (widget) {
                widget.value = job.job_id;
            }
        }
    }, [isOpen, jobId, job]);

    return (
        <div>
            <Modal show={isOpen}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Plan Details</h5>
                    </div>
                    <div className="modal-body">
                        <ul className="list-group">
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Job ID
                                <span className="badge bg-primary rounded-pill">{jobId}</span>
                            </li>
                            {
                                job && job.plan && Object.keys(job.plan).map(key => (
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {key}
                                        <span className="badge bg-primary rounded-pill">{job.plan[key]}</span>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={hideModal}>Close</button>
                        <button type="button" className="btn btn-primary" id="w_PlanDirectivesButton" onClick={directives}>Directives</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default PlanDialog;