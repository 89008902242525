//
// Log in dialog.
//
// https://www.pluralsight.com/guides/working-with-bootstraps-modals-react
//
import 'bootstrap/dist/js/bootstrap.min.js';
import Modal from "react-bootstrap/Modal";
import { useState } from 'react';
import gEventRouter from "../script/event_router.js";
import gUtilities from '../script/utilities';
import gSession from '../script/session.js';

const kUserGroups = [
    // "dose",   all users are in group 'dose'
    "job",
    "mcnp",
    "admin"

];

const AddUserDialog = () => {
    const [isOpen, setIsOpen] = useState(false);

    const showModal = () => {
        setIsOpen(true);
    };

    const hideModal = () => {
        setIsOpen(false);
    };

    async function hash(username, password) {
        let hashword = await gUtilities.sha256(password);
        hashword = await gUtilities.sha256(username + "+" + hashword);

        return hashword;
    }

    async function onCreate() {
        let username = document.querySelector("#w_AddUserUsername").value;
        let password = document.querySelector("#w_AddUserPassword").value;

        if (username.length === 0) {
            gUtilities.showError("Create User", "Username required");
            return;
        }

        if (password.length < 8) {
            gUtilities.showError("Create User", "Password must be at least 8 chars");
            return;
        }

        if (password.search(/\d+/) === -1) {
            gUtilities.showError("Create User", "Password contain at least one digit");
            return;
        }

        if (password.search(/[a-z]/) === -1) {
            gUtilities.showError("Create User", "Password contain at least one lower case letter");
            return;
        }

        if (password.search(/[A-Z]/) === -1) {
            gUtilities.showError("Create User", "Password contain at least one upper case letter");
            return;
        }

        let request = {
            service: "useradmin.create",
            data: {
                username: username,
                hashword: await hash(username, password),
                groups: ["dose"]
            }
        };

        let id;
        let widget;
        for (let name of kUserGroups) {
            id = `w_${name}_Switch`;
            widget = document.querySelector("#" + id);
            if (widget.checked) {
                request.data.groups.push(`${name}`);
            }
        }

        let response = await gSession.exchange(request);

        if (response.isError) {
            gUtilities.showError("Create User", response.message);
        } else {
            setIsOpen(false);
        }
    };

    gEventRouter.register("AddUserDialog.showModal", showModal);

    return (
        <div>
            <Modal show={isOpen}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="w_AddUserDialogHeader">Create User</h5>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label className="col-form-label mt-4" htmlFor="w_AddUserUsername">Username</label>
                            <input type="text" className="form-control" placeholder="username" id="w_AddUserUsername"></input>
                        </div><div className="form-group">
                            <label className="col-form-label mt-4" htmlFor="w_AddUserPassword">Password</label>
                            <input type="password" className="form-control" placeholder="" id="w_AddUserPassword"></input>
                        </div>
                        <div>
                            <fieldset className="form-group">
                                <legend className="mt-4">Groups</legend>
                                <ul class="list-group">
                                    {
                                        kUserGroups.map(name => (
                                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                                <span className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" id={`w_${name}_Switch`}></input>
                                                    <label className="form-check-label" htmlFor={`w_${name}_Switch`}>{name}</label>
                                                </span>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </fieldset>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={hideModal}>Close</button>
                        <button type="button" className="btn btn-primary" onClick={onCreate}>Create</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default AddUserDialog;