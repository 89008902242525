//
// Represents current login session for one user.
//
import gUtilities from '../script/utilities.js';

const k_exchange_path = '/user/session';

class Session {
  constructor() {
    this.__session = null;
  }

  // Get the current user's DB data.
  user() {
    return this.__session.record;
  }

  version() {
    if (this.isLoggedIn()) {
      return this.__session.version;
    }

    return '0.0.0';
  }

  // Returns the server's release context { prod, test, dev }
  releaseContext() {
    return this.__session.context;
  }

  // Returns true if the server's release context is production
  isProduction() {
    return this.__session.context === 'prod';
  }

  isLoggedIn() {
    return this.__session !== null;
  }

  isInGroup(name) {
    if (!this.isLoggedIn()) {
      return false;
    }

    return this.__session.record.groups.includes(name);
  }

  //
  // Fetch data from /user/session using user's login token
  //
  async exchange(request) {
    let response;
    let path;

    if (request.service === 'user.login' || this.__session) {
      request.session_token = this.__session
        ? this.__session.token
        : 'LOGIN';

      try {
        // path = this.__lookupPath(request);
        path = process.env.REACT_APP_API_URL
          ? `https://das.dev.alphabeam.net/session` //to-do: need to fix env cache issue in npm build
          : this.__lookupPath(request);
        response = await gUtilities.postJson(path, request);
        response = process.env.REACT_APP_API_URL
          ? response.result.body.response
          : response;
      } catch (ex) {
        response = { isError: true, message: ex.toString() };
      }
    } else {
      response = { isError: true, message: 'User is not logged in' };
    }

    return response;
  }

  // Returns true if password matches user
  async verifyPassword(password) {
    let session = new Session();

    let response = await session.login(
      this.__session.record.username,
      password
    );

    if (response.isError) {
      return false;
    }

    return true;
  }

  async login(username, password) {
    let hashword;
    let isHashed;
    if (gUtilities.isCryptoAvailable()) {
      isHashed = true;
      hashword = await gUtilities.sha256(password);
      hashword = await gUtilities.sha256(username + '+' + hashword);
    } else {
      // http on a LAN
      isHashed = false;
      hashword = password;
    }

    let request = {
      service: 'user.login',
      username: username,
      hashword: hashword,
      is_hashed: isHashed,
      session_token: 'LOGIN',
    };

    let response;
    try {
      response = await this.exchange(request);
    } catch (ex) {
      response = { isError: true, message: ex.toString() };
    }

    if (!response.isError) {
      this.__session = response.session;
      response = {};
    }

    return response;
  }

  async logout() {
    let request = {
      service: 'user.logout',
    };

    let response;
    try {
      response = await this.exchange(request);
    } catch (ex) {
      response = { isError: true, message: ex.toString() };
    }

    this.__session = null;

    if (response.isError) {
      alert('ERROR: ' + response.message);
      return false;
    }

    return true;
  }

  __lookupPath(request) {
    let dot = request.service.indexOf('.');
    if (dot === -1) {
      throw new Error('Invalid service specification');
    }

    let path = k_exchange_path;

    return path;
  }
}

const gSession = new Session();

export default gSession;
