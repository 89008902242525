import { useState, useEffect } from 'react'

import gSession from '../script/session';
import gUtilities from '../script/utilities';
import gDataStore from "../script/data_store.js";

import TableWidget from './TableWidget';

const LogPage = () => {
    const empty = gUtilities.convertDbResultToTableData([]);
    const [job, setJob] = useState(null);
    const [data, setData] = useState(null);
    const [banner, setBanner] = useState(empty);

    let currentJob = gDataStore.retrieve("CurrentJob", null);
    if (!job) {
        setJob(currentJob);
        updateBanner(currentJob);
    } else if (currentJob && currentJob.job_id !== job.job_id) {
        setJob(currentJob);
        updateBanner(currentJob);
    }

    function onCurrentJob(job) {
        setJob(job);
        updateBanner(currentJob);
    }
    gDataStore.onStore("CurrentJob", onCurrentJob);

    function updateBanner(job) {
        setBanner({
            header: [ "Fingerprint", "Plan Name", "Job State", "Machine Name", "Simulator"],
            columns: [ "fingerprint", "plan_name", "state", "machine_name", "simulator_name"],
            rows: [
                [gUtilities.fingerprint(job.job_id), job.plan_name, job.state, job.machine_name, job.simulator_name]
            ]
        });
    }

    const update = async () => {
        let request = {
            service: "job.logs",
            job_id: job.job_id
        };

        let response = await gSession.exchange(request);

        if (!isMounted()) {
            return;
        }

        let tableData;
        if (response.isError) {
            gUtilities.showError("Get Logs", response.message);
            setData(null);
        } else {
            tableData = response
        }

        let rows = tableData.rows;
        // latest datetime at top of table
        let sorted = rows.sort((l, r) => {
            if (l[0] < r[0]) {
                return 1;
            } else if (l[0] > r[0]) {
                return -1;
            }

            return 0;
        });

        let errors = [];
        for (let row of sorted) {
            if (row[1] === "ERROR") {
                errors.push(row);
            }
        }

        for (let row of errors) {
            sorted.unshift(row);
        }

        tableData.rows = sorted;

        setData(tableData);
    };

    // detect existance of this page's root <div>
    function isMounted() {
        return gUtilities.isMounted("w_LogPageRootDiv");
    }

    function rowClass(row) {
        if (row[1] === "ERROR") {
            return "table-danger";
        } else {
            return "table-info";
        }
    };

    function scheduleUpdate() {
        let id = setTimeout(() => {
            try {
                update();
            } catch (ex) {
                console.log("ERROR: [EXCEPTION] logs update - ", ex);
            }
        }, 500);

        return id;
    }

    useEffect(() => {
        let timer_id;
        if (job) {
            timer_id = scheduleUpdate();
        }

        // cleanup function: stop updates.
        return () => {
            if (!isMounted()) {
                clearTimeout(timer_id);
            }
        };
    }, [job, data]);

    return (
        <div>
            <div className="alert alert-dismissible alert-warning">
                <h4 className="alert-heading">Job Logs</h4>
                <TableWidget data={banner} />
            </div>
            <div id="w_LogPageRootDiv">
                {
                    data &&
                    <TableWidget data={data} rowClassFunction={rowClass} />
                }
            </div>
        </div>
    );
}

export default LogPage;