//
// Singleton global data.
//
class DataStore {
    constructor() {
        this.__table = {};
        this.__callbacks = {}
    }

    // Store data
    store(name, data) {
        this.__table[name] = data;

        setTimeout(() => {
            this.__fire(name);
        }, 10);
    }

    // Returns true if the named data is stored
    exists(name) {
        return this.__table.hasOwnProperty(name);
    }

    // Returns the named data or null
    retrieve(name, defaultValue = null) {
        let data;
        if (this.__table.hasOwnProperty(name)) {
            data = this.__table[name];
        } else {
            data = defaultValue;
        }

        return data;
    }

    // Returns true if and only if the named data exists and is *true*
    isTrue(name) {
        return this.exists(name) && this.retrieve(name) === true;
    }

    //
    // Register an event listener for changes to the named data
    // callback has the folling signature:
    //     void callback(name, data)
    //
    onStore(name, callback) {
        if (!this.__callbacks.hasOwnProperty(name)) {
            this.__callbacks[name] = [];
        }

        this.__callbacks[name].push(callback);
    }

    clear() {
        this.__table = {};
        this.__callbacks = {};
    }

    __fire(name) {
        if (!this.__callbacks.hasOwnProperty(name)) {
            return;
        }

        let data;
        let callbacks = this.__callbacks[name];
        for (let callback of callbacks) {
            try {
                data = this.retrieve(name);
                callback(name, data);
            } catch (ex) {
                console.log("ERROR:", `Data store callback failed for ${name}`);
            }
        }
    }

}

const gDataStore = new DataStore();

export default gDataStore;