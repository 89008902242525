import { useState, useEffect } from 'react'

import gSession from '../script/session';
import gUtilities from '../script/utilities';
import gDataStore from "../script/data_store.js";
import TableWidget from './TableWidget';

const JobPlotPage = () => {
    const [job, setJob] = useState(null);
    const [data, setData] = useState(null);
    const [files, setFiles] = useState([]);
    const [banner, setBanner] = useState(null);
    const [directory, setDirectory] = useState("");

    let id = 0;

    let currentJob = gDataStore.retrieve("CurrentJob", null);
    if (!job) {
        setJob(currentJob);
        setData(null);
        updateBanner(currentJob);
    } else if (currentJob && currentJob.job_id !== job.job_id) {
        setJob(currentJob);
        setData(null);
        updateBanner(currentJob);
    }

    function onCurrentJob(currentJob) {
        setJob(currentJob);

        updateBanner(currentJob);
    }
    gDataStore.onStore("CurrentJob", onCurrentJob);

    function updateBanner(job) {
        setBanner({
            header: ["Fingerprint", "Plan Name", "Job State", "Machine Name", "Simulator"],
            columns: ["fingerprint", "plan_name", "state", "machine_name", "simulator_name"],
            rows: [
                [gUtilities.fingerprint(job.job_id), job.plan_name, job.state, job.machine_name, job.simulator_name]
            ]
        });
    }

    async function getFileList() {
        let request = {
            service: "job.fileResult",
            job_id: job.job_id
        };

        let response;
        try {
            response = await gSession.exchange(request);
            if (response.isError) {
                gUtilities.showError("Job File List", response.message);
                return;
            }
        } catch (ex) {
            gUtilities.showError("Job File List", ex.toString());
            return;
        }
        console.log("DEBUG: response", response);
        let file_list = [];
        for (let fd of response) {
            if (fd.filename.startsWith(directory) && fd.filename.endsWith(".png")) {
                file_list.push(fd.filename);
            }
        }

        let bundle = {
            beam: await getMetadata(`${directory}/beam.json`),
            plots: []
        }

        setData(bundle);
        console.log("DEBUG: files", file_list);
        setFiles(file_list);
    }

    async function getPlot(filename) {
        let plot = {
            filename: filename
        };

        plot.contents = await getImage(filename);
        plot.metadata = await getMetadata(filename.substring(0, filename.length - 4) + ".json");

        return plot;
    }

    async function load(beam_directory) {
        setDirectory(beam_directory);

        getFileList();
    }

    async function getMetadata(filename) {
        let request = {
            service: "file.json",
            job_id: job.job_id,
            filename: filename
        };

        let response = await gSession.exchange(request);

        return response;
    }

    async function getImage(filename) {
        let request = {
            service: "file.contents",
            job_id: job.job_id,
            filename: filename
        };

        let response = await gSession.exchange(request);

        if (response.isError) {
            gUtilities.showError("View File", response.message);
            return { isError: true };
        }

        return response.contents;
    }

    function onLoadBeam1() {
        load("beam-1/");
    }

    function onLoadBeam2() {
        load("beam-2/");
    }

    function sortPlots(plots) {
        plots.sort((l, r) => {
            if (l.metadata.basis < r.metadata.basis) {
                return -1;
            }

            if (l.metadata.basis > r.metadata.basis) {
                return 1;
            }

            if (l.filename === r.filename) {
                return 0;
            }

            if (l.filename < r.filename) {
                return -1;
            }

            return 1;
        });
    }

    useEffect(async () => {
        console.log("DEBUG: useEffect", files.length);
        let file_list;

        if (files.length > 0) {
            file_list = JSON.parse(JSON.stringify(files));  // clone
            let bundle = JSON.parse(JSON.stringify(data));  // clone

            let filename = file_list.shift();
            let plot;

            console.log("DEBUG:", filename);
            try {
                plot = await getPlot(filename);
                bundle.plots.push(plot);
            } catch (ex) {
                gUtilities.showError("Load Plots", ex.toString());
                return;
            }

            setTimeout(() => {
                sortPlots(bundle.plots);
                setData(bundle);
                setFiles(file_list);
            }, 10);
        }
    }, [files]);

    function plotStyle() {
        let style = {
            margin: "auto",
            width: "1000px",
            height: "640px",
            display: "flex",
            paddingTop: "20px",
            background: "black",
            color: "white"
        };

        return style;
    }

    function imageStyle(basis) {
        let style = {
            width: 600,
            height: 600
        };

        if (basis === "yz") {
            style.transform = "rotate(270deg)";
        }

        return style;
    }

    function tableStyle() {
        let style = {
            padding: "10px"
        };

        return style;
    }

    function backgroundStyle() {
        let style = {
            background: "#333333"
        }

        return style;
    }

    function buttonStyle() {
        let style = {
            width: "80px",
            marginLeft: "40px"
        }

        return style;
    }

    function imgSrc(contents) {
        return `data:image/png;charset=utf-8;base64,${contents}`;
    }

    function lookupDirection(basis) {
        if (basis === "xy") {
            return "Beam's eye view";
        } else if (basis === "xz") {
            return "Looking down";
        } else {
            return "Table-top plane";
        }
    }

    function nextFilename() {
        if (files && files.length > 0) {
            return `Loading ${files[0]}`;
        }

        return "Done";
    }

    return (
        <div style={backgroundStyle()}>
            <div className="alert alert-dismissible alert-warning">
                <h4 className="alert-heading">BSA and Dose Grid Plots</h4>
                {banner &&
                    <TableWidget data={banner} />
                }
            </div>
            <div className="x-row">
                <button type="button" className="btn btn-dark btn-sm" style={buttonStyle()} onClick={onLoadBeam1}>Beam 1</button>
                {job && job.nbeams >= 2 &&
                    <button type="button" className="btn btn-dark btn-sm" style={buttonStyle()} onClick={onLoadBeam2}>Beam 2</button>
                }
                {files && files.length > 0 &&
                    <span class="badge bg-secondary" style={{ marginLeft: "20px" }}>{nextFilename()}</span>
                }
            </div>
            <br />
            <div>
                {
                    data && data.plots.map((plot) => (
                        <>
                            <div key={(id++).toString()} style={plotStyle()}>
                                <div style={tableStyle()}>
                                    <table>
                                        <tr><td>DIRECETION</td><td>{lookupDirection(plot.metadata.basis)}</td></tr>
                                        <tr><td>PLOT NAME</td><td>{plot.filename}</td></tr>
                                        <tr><td>PLOT ORIGIN</td><td>{plot.metadata.origin}</td></tr>
                                        <tr><td>PLOT BASIS</td><td>{plot.metadata.basis}</td></tr>
                                    </table>
                                </div>
                                <div>
                                    <img src={imgSrc(plot.contents)} alt="Red dot" style={imageStyle(plot.metadata.basis)} />
                                </div>
                            </div>
                            <br />
                        </>
                    ))
                }
            </div>
        </div >
    );
}

export default JobPlotPage;