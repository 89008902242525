//
// Group box-like input widget with title.
//
import { useState } from 'react';

import gUtilities from '../script/utilities';

//
// props:
//   string    title      - header for text
//   string    text       - characters to show in <input>
//
const TextDisplay = (props) => {
    const [widgetID] = useState(gUtilities.widgetID());

    return (
        <div className="form-group">
            <label className="col-form-label mt-1" htmlFor={widgetID}>{props.title}</label>
            <div className="input-group mb-1">
                <input type="text" className="form-control" id={widgetID} value={props.text} readOnly={true}></input>
            </div>
        </div>
    );
}

export default TextDisplay;