import { useEffect, useState } from 'react';
import 'bootstrap/dist/js/bootstrap.min.js';
import Modal from "react-bootstrap/Modal";
import gEventRouter from "../script/event_router.js";
import gUtilities from '../script/utilities';

function JobFileUploadDialog() {
    const [jobId, setJobId] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const showModal = (data) => {
        let job = data;
        setJobId(job.job_id);
        setIsOpen(true);
    };

    const hideModal = () => {
        setIsOpen(false);
    };

    gEventRouter.register("JobFileUploadDialog.showModal", showModal);

    const browse = (event) => {
        const widget = document.createElement("input");
        widget.setAttribute(
            "type",
            "file"
        );

        widget.setAttribute(
            "name",
            "file"
        );

        widget.addEventListener(
            "change",
            (event) => {
                setSelectedFile(event.target.files[0]);
                widget.parentNode.removeChild(widget);
            }
        );

        document.body.appendChild(widget);

        // popup the file browser dialog
        widget.click();
    };

    function getUrl(filename) {
        let host = document.location.host;
        let protocol = document.location.protocol;

        const url = `${protocol}//${host}/job/upload?job_id=${jobId}&filename=${filename}`;

        return url;
    }

    const upload = () => {
        const formData = new FormData();

        formData.append('file', selectedFile);

        const url = getUrl(selectedFile.name);
        fetch(url,
            {
                method: 'POST',
                body: formData,
            })
            .then((response) => {
                hideModal();
            })
            .catch((error) => {
                gUtilities.showError("Job File Upload", error.toString());
            });
    };

    useEffect(() => {
        if (jobId) {
            document.querySelector("#w_JobFileUploadJobId").value = jobId;
        }

        if (selectedFile) {
            document.querySelector("#w_JobFileUploadFilename").value = selectedFile.name;
            document.querySelector("#w_JobFileUploadSize").value = selectedFile.size;
        }
    }, [selectedFile, jobId]);

    return (
        <div>
            <Modal show={isOpen}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Upload Job File</h5>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label className="col-form-label mt-4" htmlFor="w_JobFileUploadJobId">Job ID</label>
                            <input type="text" className="form-control" readOnly="" placeholder="Job ID" id="w_JobFileUploadJobId"></input>
                        </div>
                        <div className="form-group">
                            <label className="col-form-label mt-4" htmlFor="w_JobFileUploadFilename">Filename</label>
                            <input type="text" className="form-control" readOnly="" placeholder="filename" id="w_JobFileUploadFilename"></input>
                        </div>
                        <div className="form-group">
                            <label className="col-form-label mt-4" htmlFor="w_JobFileUploadSize">Size</label>
                            <input type="text" className="form-control" readOnly="" placeholder="0" id="w_JobFileUploadSize"></input>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={hideModal}>Close</button>
                        <button type="button" className="btn btn-primary" onClick={browse}>Browse</button>
                        <button type="button" className="btn btn-primary" onClick={upload}>Upload</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default JobFileUploadDialog;