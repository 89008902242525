//
// Password confirmation dialog.
//
// Params must have the following callback:
//   onConfirm(<status>) - where status string is one of:
//                 SUCCESS  - password is valid
//                 FAILURE  - password is invalid
//                 CANCELED - operation canceled
//
import 'bootstrap/dist/js/bootstrap.min.js';
import Modal from "react-bootstrap/Modal";
import { useState } from 'react';

import gUtilities from '../script/utilities';
import gSession from '../script/session.js';

const ConfirmPasswordDialog = (params) => {
    const [isOpen, setIsOpen] = useState(true);

    const hideModal = () => {
        setIsOpen(false);
    };

    async function onConfirm() {
        let widget = document.getElementById("w_VerifyPassword");
        let password = widget.value;

        let result = await gSession.verifyPassword(password);
        let status = result ? "SUCCESS" : "FAILURE";

        try {
            params.onConfirm(status);
        } catch (ex) {
            gUtilities.showError("Verify", "Operation failed (callback) " + ex.toString());
        }

        hideModal();
    }

    function onCancel() {
        try {
            params.onConfirm("CANCELED");
        } catch (ex) {
            gUtilities.showError("Verify", "Operation failed (callback) " + ex.toString());
        }

        hideModal();
    }

    return (
        <div>
            <Modal show={isOpen}>
                <div className="modal-content">
                    <div className="modal-header text-danger">
                        <h5 className="modal-title text-danger">Please Verify Your Password</h5>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label className="col-form-label mt-4" htmlFor="w_VerifyPassword">Password</label>
                            <input type="password" className="form-control" placeholder="" id="w_VerifyPassword"></input>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={onCancel}>Cancel</button>
                        <button type="button" className="btn btn-primary" onClick={onConfirm}>Confirm</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default ConfirmPasswordDialog;