//
// Various utility functions for the browser.
//
import gEventRouter from './event_router.js';

class Utilities {
    constructor() {
        this.__widgetCounter = 1000;
    }

    showError(operation, message) {
        let data = {
            operation: operation,
            message: message
        }

        gEventRouter.invoke("ErrorDialog.showModal", data);
    }

    //
    // Pending proper web URL, this switches to the
    // ngrok URL from the local tunnel url, if used.
    //
    async verifyUrl() {
        if (!document.location.host.endsWith(".lt")) {
            return;
        }

        console.log("DEBUG: Local Tunnel URL (.lt) - redirecting");

        let request = {
            service: "api.url"
        };

        let response = await this.postJson("/api", request);
        if (!response.isError) {
            window.location = response.message;
        }
    }

    //
    // POST JavaScript object as JSON to the specified url.
    // Response is JavaScript object deserialized from JSON.
    // Returns a promise for await.
    //
    postJson(path, request) {
        let p = fetch(path, {
            method: "POST",
            headers: new Headers({ "Content-Type": "application/json" }),
            body: JSON.stringify(request)
        }).then(raw => {
            if (raw.ok) {
                return raw.json()
            } else {
                return { isError: true, message: "Failed to fetch data: " + raw.toString() };
            }
        }).then(response => {
            return response;
        });

        return p;  // let response = await postJson(...);
    }

    //
    // Translate a DB find query result into the following schema:
    //   header  - titles to be displayed in table on UI
    //   columns - original titles
    //   rows    - array of values, one per DB record
    //
    //   data = {
    //      header: [title1, title2, ...]
    //      rows: [
    //          [ value1, value2, ...]
    //          [ value1, value2, ...]
    //          [ value1, value2, ...]
    //      ],
    //      columns: [column1, column2, ...]
    //   }
    //
    //   options = {
    //       // any subset of headers can be renamed
    //       headerTitles: {
    //           title1: "replacement1",
    //           title2: "replacement2",
    //           ...
    //       }
    //   }
    //
    convertDbResultToTableData(result, options) {
        let data = {
            header: [],
            rows: [],
            columns: []
        };

        if (!result) {
            return data;
        }

        // build header
        let title;
        if (result.length > 0) {
            for (let column in result[0]) {
                if (options && options.headerTitles && options.headerTitles.hasOwnProperty(column)) {
                    title = options.headerTitles[column];
                } else {
                    title = column;
                }

                data.header.push(title);
                data.columns.push(column);
            }
        }

        let columns = data.columns;

        // build rows
        result.forEach(record => {
            let row = []
            let column;

            for (let i in columns) {
                column = columns[i];
                row.push(record[column]);
            }

            row.push(record);  // cookie

            data.rows.push(row);
        });

        // Add a column for the record as cookie
        data.header.push("");
        data.columns.push("_cookie");

        return data;
    }

    //
    // Reverses the convertDbResultToTableData() operation above for one row.
    // Returns the row's data as an object { column[i]: row[i], ... }
    //
    convertTableRowToData(tableData, row) {
        let rowData = {};
        let column;

        for (let i in tableData.columns) {
            column = tableData.columns[i];

            rowData[column] = row[i];
        }

        return rowData;
    }

    //
    // Given tableData from convertDbResultToTableData() above,
    // returns the value at specified column name and row index
    // OR empty string if not found.
    // 
    tableValue(tableData, columnName, rowIndex) {
        for (let i in tableData.columns) {
            if (tableData.columns[i] === columnName) {
                return tableData.rows[rowIndex][i];
            }
        }

        return "";
    }

    //
    // Given tableData from convertDbResultToTableData() above,
    // returns the index of the specified column name.
    // 
    tableColumnIndex(tableData, columnName) {
        for (let i in tableData.columns) {
            if (tableData.columns[i] === columnName) {
                return i;
            }
        }

        return -1;
    }

    // Set the header to empty string so TableWidget won't display
    hideTableColumn(tableData, name) {
        let i = this.tableColumnIndex(tableData, name);
        if (i !== -1) {
            tableData.header[i] = "";
        }
    }

    // Set the header for a specified column name, e.g. stats => Statistics
    setTableHeader(tableData, name, header) {
        let i = this.tableColumnIndex(tableData, name);
        if (i !== -1) {
            tableData.header[i] = header;
        }
    }

    isCryptoAvailable() {
        if (crypto.subtle) {
            return true;
        }

        return false;
    }
    // Returns SHA-256 hash of text as a hex stirng (64 chars)
    async sha256(text) {
        if (!crypto.subtle) {
            throw new Error("Crypto is not available");
        }

        const encoder = new TextEncoder();
        const encoded = encoder.encode(text);
        const buffer = await crypto.subtle.digest('SHA-256', encoded);
        const array = Array.from(new Uint8Array(buffer));
        const hash = array.map(b => b.toString(16).padStart(2, '0')).join('');

        return hash;
    }

    //
    // Returns a GUID
    //
    uuid() {
        return crypto.randomUUID();
    }

    // Returns a unique HTML id for a widget
    widgetID() {
        this.__widgetCounter++;

        return `w_Widget-${this.__widgetCounter}`;
    }

    //
    // Returns current datetime in ISO format:
    //    YYYY-MM-DDThh:mm:ss
    //
    nowIso() {
        return (new Date()).toISOString();
    }

    //
    // Returns the first 8 chars of a GUID
    //     01234567-0123-0123-0123-0123456789AB => 01234567
    //
    fingerprint(guid) {
        if (guid && guid.length >= 8) {
            return guid.slice(0, 8);
        }

        return guid;
    }

    //
    // Returns the data value at a leaf node.
    // data - object graph
    // path - dot separated sequence of tokens
    //
    // EXAMPLE:
    // data = { 
    //     flags: {
    //         add: {
    //             allowed: true
    //         },
    //         remove: {
    //             allowed: false
    //         }
    //      }
    // }
    //
    // let allowed = leafValue(data, "flags.remove.allowed") === true;
    //
    leafValue(data, path) {
        let tokens = path.split('.');
        if (tokens[0] === '$') {
            tokens.shift();
        }

        for (let token of tokens) {
            if (data.hasOwnProperty(token)) {
                data = data[token];
            } else {
                return null;
            }
        }

        return data;
    }

    //
    // Boolean test of leaf value === true
    // data - object graph
    // path - dot separated sequence of tokens
    //
    // EXAMPLE:
    // if (leafTrue(data, "flags.remove.allowed")) { ... }
    //
    leafTrue(data, path) {
        return this.leafValue(data, path) === true;
    }

    //
    // Create a URL for file upload.
    //   path     - url path for file handler
    //   folder   - dsv identifier for file (names separated by dots)
    //   filename - name of the file
    //
    // Example:
    //   path     = /system/store
    //   folder   = system.service.notes
    //   filename = notes.txt
    //
    //   url      = https://host.com/system/store?id=system.service.notes&filename=notes.txt
    //
    fileUploadUrl(path, id, filename) {
        let host = document.location.host;
        let protocol = document.location.protocol;

        if (path.indexOf("?") === -1) {
            path += "?";
        } else {
            path += "&";
        }

        const url = `${protocol}//${host}${path}id=${id}&filename=${filename}`;

        return url;
    }

    //
    // Upload a file to the specified URL
    //   path     - path to server handler
    //   id       - identifier for file
    //   fdata    - data from <input type="file">
    //   callback - optional progress handler f(int)
    //
    //   response - server response OR { isError: true, message: string }
    // 
    uploadFile(path, id, fdata, callback = null) {
        const formData = new FormData();
        formData.append('file', fdata);

        const url = this.fileUploadUrl(path, id, fdata.name);

        let p = new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();

            if (callback) {
                xhr.upload.addEventListener('progress', function (e) {
                    if (e.lengthComputable) {
                        const progress = Math.round((e.loaded * 100) / e.total);
                        try {
                            callback(progress);
                        } catch (ex) {
                            console.log("ERROR: [upload]", ex);
                        }
                    }
                }, false);
            }

            xhr.addEventListener('load', () => {
                try {
                    let response = JSON.parse(xhr.responseText);
                    resolve(response);
                } catch (ex) {
                    reject({ isError: true, message: ex.toString(), source: "load exception" });
                }
            }, false);

            xhr.addEventListener('error', (err) => {
                reject({ isError: true, message: err.toString(), source: "error listener" });
            }, false);

            xhr.open('POST', url, true);
            xhr.setRequestHeader("enctype", "multipart/form-data");
            xhr.send(formData);
        });

        return p;  // let response = await uploadFile(...);
    }

    //
    // Extract the file name from the specified path
    //   string path - path to file, e.g. root/logs/log.txt
    // 
    // Returns file name with extension, e.g. log.txt
    //
    parseFilename(path) {
        let tokens;
        let filename;

        try {
            tokens = path.split('/');
            filename = tokens[tokens.length - 1];
        }
        catch (ex) {
            filename = path;
        }

        return filename;
    }

    //
    // Detect if component has been dismounted
    // by querying the DOM for the specified widget id
    // 
    isMounted(id) {
        let widget = document.getElementById(id);

        if (widget) {
            return true;
        }

        return false;
    }

    //
    // Returns true if the provided argument is an object literial, i.e. {...}
    //
    isObject(o) {
        return o?.constructor === Object;
    }
}

const gUtilities = new Utilities();

export default gUtilities;