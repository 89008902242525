//
// TLS Dose Engine Database page
//
import { useState } from "react";

import gSession from '../script/session.js';
import gUtilities from '../script/utilities';

import TextDialog from "./TextDialog.js";
import TableWidget from './TableWidget';
import DatabaseDialog from "./DatabaseDialog.js";

const DatabasePage = () => {
    let empty = { header: [], columns: [], rows: [] };
    const [data, setData] = useState(empty);
    const [json, setJson] = useState(null);
    const [result, setResult] = useState(null);
    const [showQuery, setShowQuery] = useState(false);
    const [queryStatement, setQueryStatement] = useState(null);

    async function submit(statement) {
        let request = {
            service: "admin.db.statement",
            statement: statement
        };

        let response = await gSession.exchange(request);

        if (response.isError) {
            gUtilities.showError("Database Error", response.message);
        } else if (response.notFound) {
            setData(empty);
        } else {
            // update table if query result
            if (statement.operation.indexOf("find") === 0) {
                setResult(response);
                filterData(response);
            }
        }
    }

    function findCommonColumns(docs) {
        let table = {};

        for (let prop of Object.getOwnPropertyNames(docs[0])) {
            if (typeof (docs[0][prop]) === "string") {
                table[prop] = "string";
            } else if (typeof (docs[0][prop]) === "number") {
                table[prop] = "number";
            }
        }

        for (let doc of docs) {
            for (let prop of Object.getOwnPropertyNames(table)) {
                if (!(doc.hasOwnProperty(prop) && typeof (doc[prop]) == typeof (table[prop]))) {
                    delete table[prop];
                }
            }
        }

        return table;
    }

    function filterData(docs) {
        if (!docs || docs.length === 0) {
            return empty;
        }

        let common = findCommonColumns(docs);
        let tableData = {
            header: ["_key_"],
            columns: ["_key_"],
            rows: []
        };

        for (let prop of Object.getOwnPropertyNames(common)) {
            tableData.header.push(prop);
            tableData.columns.push(prop);
        }

        let row;
        for (let i = 0; i < docs.length; i++) {
            row = [i];  // _key_

            for (let prop of Object.getOwnPropertyNames(common)) {
                row.push(docs[i][prop]);
            }

            tableData.rows.push(row);
        }

        setData(tableData);
    }

    function view(item) {
        let text = JSON.stringify(result[item["_key_"]], null, 4);

        setJson(text);
    }

    let buttonData = {
        buttons: [
            {
                label: "View",
                callback: view
            }
        ]
    };

    function onCloseTextDialog() {
        setJson(null);
    }

    function onEditTextDialog(modified) {
        let data;
        try {
            data = JSON.parse(modified);
        } catch (ex) {
            gUtilities.showError("Edit Record", ex.toString());
            return;
        }

        let id = data["_id"];
        delete data["_id"];

        let statement = {
            operation: "updateOne",
            collection: queryStatement.collection,
            query: {
                "_id": id
            },
            clause: {
                "$set": data
            }
        };

        submit(statement);
        submit(queryStatement);  // refresh the data after edit

        setJson(null);
    }

    function onQuery(statement) {
        statement.operation = "find";

        setShowQuery(false);
        setQueryStatement(statement);

        submit(statement);
    }

    return (
        <div>
            <button type="button" className="btn btn-dark" onClick={() => { setShowQuery(true); }}>Query</button>
            <div>
                <TableWidget data={data} buttonData={buttonData} />
            </div>
            {json &&
                <TextDialog header="Database Record" text={json} onClose={onCloseTextDialog} onEdit={onEditTextDialog} />
            }
            {showQuery &&
                <DatabaseDialog onClose={() => { setShowQuery(false); }} onSubmit={onQuery} />
            }
        </div>
    );
}

export default DatabasePage;