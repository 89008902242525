import { useState, useEffect } from 'react'

import Plot from 'react-plotly.js';

import gSession from '../script/session';
import gUtilities from '../script/utilities';
import gDataStore from "../script/data_store.js";
import TableWidget from './TableWidget';

//
// DVH and histogram plot. Uses Plotly.
// npm install react-plotly.js plotly.js
// https://plotly.com/javascript/react/
//
const DvhPage = () => {
    const empty = gUtilities.convertDbResultToTableData([]);
    const [job, setJob] = useState(null);
    const [data, setData] = useState(null);
    const [dicom, setDicom] = useState(null);
    const [banner, setBanner] = useState(empty);

    let roiLabel = "";
    let rbe = 1.0;

    let currentJob = gDataStore.retrieve("CurrentJob", null);
    if (!job) {
        setJob(currentJob);
        updateBanner(currentJob);
    } else if (currentJob && currentJob.job_id !== job.job_id) {
        setJob(currentJob);
        updateBanner(currentJob);
    }

    function onCurrentJob(job) {
        setJob(job);
        updateBanner(currentJob);
    }
    gDataStore.onStore("CurrentJob", onCurrentJob);

    function updateBanner(job) {
        setBanner({
            header: ["Fingerprint", "Plan Name", "Job State", "Machine Name", "Simulator"],
            columns: ["fingerprint", "plan_name", "state", "machine_name", "simulator_name"],
            rows: [
                [gUtilities.fingerprint(job.job_id), job.plan_name, job.state, job.machine_name, job.simulator_name]
            ]
        });
    }

    function plot(response) {
        // Convert MCTAL to physical dose by dividing by RBE
        if (response.source === "MCTAL") {
            response.x = response.x.map((dose) => {
                return dose / rbe;
            });
        }

        // RayStation DVH is in cGy
        response.x = response.x.map((dose) => {
            return 100.0 * dose;
        })

        let curves = [
            {
                x: response.x,
                y: response.dvh,
                mode: "lines",
                type: "scatter",
                name: `${roiLabel} DVH`,
                line: {
                    color: "red"
                },
                yaxis: 'y'
            },
            {
                x: response.x,
                y: response.histogram,
                mode: "lines",
                type: "scatter",
                name: `${roiLabel} Histogram`,
                line: {
                    color: "black"
                },
                yaxis: "y2"
            }
        ];

        let layout = {
            xaxis: { title: response.xlegend },
            yaxis: { title: response.ylegend },
            yaxis2: { title: "Histogram", color: "black", overlaying: 'y', side: "right" },
            title: response.titles
        };

        let data = {
            curves: curves,
            layout: layout
        };

        setData(data);
    }

    async function getData(source, beam_number, roi_number) {
        let request = {
            service: "job.dvh",
            job_id: job.job_id,
            data: {
                source: source,
                beam_number: beam_number,
                roi_number: roi_number
            }
        };

        setData(null);

        let response = await gSession.exchange(request);

        if (response.isError) {
            gUtilities.showError("DVH Plot", response.message);
        } else {
            plot(response);
        }
    }

    async function onPlot() {
        let widget = document.querySelector("#w_RoiSelect");
        roiLabel = widget.options[widget.selectedIndex].text;

        widget = document.querySelector("#w_DvhMctalRadio");
        let source = widget.checked ? "MCTAL" : "DICOM";

        widget = document.querySelector("#w_DvhBeam1Radio");
        let beam_number = widget.checked ? 1 : 2;

        let roi_number;
        for (let snumber of Object.keys(dicom.roi_table)) {
            if (dicom.roi_table[snumber].label === roiLabel) {
                roi_number = dicom.roi_table[snumber].number;
                rbe = dicom.roi_table[snumber].properties["CBE_BORON"];
                console.log("RBE", rbe);

                getData(source, beam_number, roi_number);
                break;
            }
        }
    }

    async function getDicom() {
        let request = {
            service: "job.dicom",
            job_id: job.job_id
        };

        let response = await gSession.exchange(request);

        if (response.isError) {
            gUtilities.showError("DVH Plot", response.message);
        } else {
            setDicom(response);
        }
    }

    function onSelectRoi() {
    }

    useEffect(() => {
        getDicom();
    }, []);

    return (
        <div>
            <div className="alert alert-dismissible alert-warning">
                <h4 className="alert-heading">DVH and Histograms</h4>
                <TableWidget data={banner} />
            </div>
            <div className="container">
                <div className="row">
                    <fieldset className="form-group col-sm">
                        <legend className="mt-4">Data Source</legend>
                        <div className="container">
                            <div className="row">
                                <div className="form-check col-sm">
                                    <label className="form-check-label">
                                        <input type="radio" className="form-check-input" name="DvhSourceRadios" id="w_DvhMctalRadio" value="dvh_source_mctal"></input>
                                        Tally
                                    </label>
                                </div>
                                <div className="form-check col-sm">
                                    <label className="form-check-label">
                                        <input type="radio" className="form-check-input" name="DvhSourceRadios" id="w_DvhDicomRadio" value="dvh_source_dicom"></input>
                                        Dose
                                    </label>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset className="form-group col-sm">
                        <legend className="mt-4">Beam Number</legend>
                        <div className="container">
                            <div className="row">
                                <div className="form-check col-sm">
                                    <label className="form-check-label">
                                        <input type="radio" className="form-check-input" name="DvhBeamRadios" id="w_DvhBeam1Radio" value="dvh_beam_1" ></input>
                                        Beam 1
                                    </label>
                                </div>
                                <div className="form-check col-sm">
                                    <label className="form-check-label">
                                        <input type="radio" className="form-check-input" name="DvhBeamRadios" id="w_DvhBeam2Radio" value="dvh_beam_2"></input>
                                        Beam 2
                                    </label>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    {dicom &&
                        <div className="col-sm">
                            <label htmlFor="w_RoiSelect" className="form-label mt-4">ROI</label>
                            <div className="input-group mb-3">
                                <select className="form-select" onChange={onSelectRoi} id="w_RoiSelect" aria-describedby="w_JobResetButton">
                                    <option>Select</option>
                                    {
                                        Object.keys(dicom.roi_table).map(snumber => (
                                            <option>{dicom.roi_table[snumber].label}</option>
                                        ))
                                    }
                                </select>
                                <button type="button" className="btn btn-primary" id="w_PlotButton" onClick={onPlot}>Plot</button>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {data &&
                <Plot
                    data={data.curves}
                    layout={data.layout}
                />
            }
        </div>
    );
}

export default DvhPage;