//
// Plan Directive Dialog.
//
// https://www.pluralsight.com/guides/working-with-bootstraps-modals-react
//
import 'bootstrap/dist/js/bootstrap.min.js';
import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from 'react';
import gEventRouter from "../script/event_router.js";
import gUtilities from '../script/utilities';
import gSession from '../script/session.js';

// TODO: Retrieve from DB(?)
const kDefaultBoronFmExpression = "-1.602e-10 5010 h -4"
const kDefaultGammaFmExpression = "1.602e-10"
const kDefaultHydrogenFmExpression = "-1.602e-10 1001 h -4"
const kDefaultNitrogenFmExpression = "-1.602e-10 7014 h -4"

const DirectiveDialog = () => {
    const [job, setJob] = useState(null);
    const [plan, setPlan] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const showModal = (job) => {
        setJob(job);
        setPlan(null);

        setIsOpen(true);
    };

    const hideModal = () => {
        setIsOpen(false);
    };

    function fillDirectives() {
        let widget;
        let directives = {
        };

        widget = getWidget("w_VoidCardSwitch");
        if (widget && widget.checked) {
            directives.VOID = {
                default: "active",
                active: true
            };
        }

        widget = getWidget("w_FluxCardSwitch");
        if (widget && widget.checked) {
            directives.FLUX = {
                default: "active",
                active: true
            };
        }

        widget = getWidget("w_UncertaintySwitch");
        if (widget && widget.checked) {
            directives.UNCERTAINTY = {
                default: "active",
                active: true
            };
        }

        widget = getWidget("w_ProtonParticleSwitch");
        if (widget && widget.checked) {
            directives.PROTON = {
                default: "active",
                active: true
            };
        }

        widget = getWidget("w_AirSwitch");
        if (widget && widget.checked) {
            directives.AIR = {
                default: "active",
                active: true
            };
        }

        widget = getWidget("w_MeshSwitch");
        if (widget && widget.checked) {
            directives.MESH = {
                default: "active",
                active: true
            };
        }

        widget = getWidget("w_NitrogenN14Switch");
        if (widget && widget.checked) {
            directives.NITROGEN_N14_TALLY = {
                default: "active",
                active: true
            };
        }

        widget = getWidget("w_HydrogenH1TallySwitch");
        if (widget && widget.checked) {
            directives.HYDROGEN_H1_TALLY = {
                default: "active",
                active: true
            };
        }

        widget = getWidget("w_StatepointRecordSwitch");
        if (widget && widget.checked) {
            directives.STATEPOINT_RECORD = {
                default: "active",
                active: true
            };
        }

        widget = getWidget("w_XrbeSwitch");
        if (widget && widget.checked) {
            directives.XRBE = {
                default: "active",
                active: true
            };
        }

        widget = getWidget("w_DiskSwitch");
        if (widget && widget.checked) {
            widget = getWidget("w_DiskRadiusTextbox");
            directives.DISK = {
                default: "radius",
                radius: toFloat("Disk Radius", widget.value)
            };
        }

        widget = getWidget("w_EnergySwitch");
        if (widget && widget.checked) {
            widget = getWidget("w_EnergyTextbox");
            directives.ENERGY = {
                default: "energy",
                energy: toFloat("Particle Energy", widget.value)
            };
        }

        widget = getWidget("w_TargetBCRSwitch");
        if (widget && widget.checked) {
            widget = getWidget("w_TargetBCRTextbox");
            directives.TARGET_BCR = {
                default: "bcr",
                bcr: toFloat("BCR", widget.value)
            };
        }

        widget = getWidget("w_HwdfSwitch");
        if (widget && widget.checked) {
            widget = getWidget("w_HwdfTextbox");
            directives.HWDF = {
                default: "hwdf",
                hwdf: toFloat("HWDF", widget.value)
            };
        }

        widget = getWidget("w_TallyMultiplierSwitch");
        if (widget && widget.checked) {
            widget = getWidget("w_TallyMultiplierTextbox");
            directives.TALLY_MULTIPLIER = {
                default: "multiplier",
                multiplier: toFloat("Tally Multiplier", widget.value)
            };
        }

        widget = getWidget("w_CountSwitch");
        if (widget && widget.checked) {
            widget = getWidget("w_CountTextbox");
            directives.COUNT = {
                default: "count",
                count: toFloat("Particle Count", widget.value)
            };
        }

        widget = getWidget("w_NodeCountSwitch");
        if (widget && widget.checked) {
            widget = getWidget("w_NodeCountTextbox");
            directives.NODE_COUNT = {
                default: "count",
                count: toFloat("Node Count", widget.value)
            };
        }

        widget = getWidget("w_UsernameSwitch");
        if (widget && widget.checked) {
            widget = getWidget("w_UsernameTextbox");
            directives.USER = {
                default: "username",
                username: widget.value
            };
        }

        widget = getWidget("w_NoteSwitch");
        if (widget && widget.checked) {
            widget = getWidget("w_NoteTextbox");
            directives.NOTE = {
                default: "note",
                note: widget.value
            };
        }

        widget = getWidget("w_TagSwitch");
        if (widget && widget.checked) {
            widget = getWidget("w_TagTextbox");
            directives.TAG = {
                default: "tag",
                tag: widget.value
            };
        }

        widget = getWidget("w_UsernameSwitch");
        if (widget && widget.checked) {
            widget = getWidget("w_UsernameTextbox");
            directives.USER = {
                default: "username",
                username: widget.value
            };
        }

        widget = getWidget("w_BoronFmExpressionSwitch");
        if (widget && widget.checked) {
            widget = getWidget("w_BoronFmExpressionTextbox");
            directives.BORON_FM_EXPRESSION = {
                default: "boron_fm_expression",
                boron_fm_expression: widget.value
            };
        }

        widget = getWidget("w_GammaFmExpressionSwitch");
        if (widget && widget.checked) {
            widget = getWidget("w_GammaFmExpressionTextbox");
            directives.GAMMA_FM_EXPRESSION = {
                default: "gamma_fm_expression",
                gamma_fm_expression: widget.value
            };
        }

        widget = getWidget("w_HydrogenFmExpressionSwitch");
        if (widget && widget.checked) {
            widget = getWidget("w_HydrogenFmExpressionTextbox");
            directives.HYDROGEN_FM_EXPRESSION = {
                default: "hydrogen_fm_expression",
                hydrogen_fm_expression: widget.value
            };
        }

        widget = getWidget("w_NitrogenFmExpressionSwitch");
        if (widget && widget.checked) {
            widget = getWidget("w_NitrogenFmExpressionTextbox");
            directives.NITROGEN_FM_EXPRESSION = {
                default: "nitrogen_fm_expression",
                nitrogen_fm_expression: widget.value
            };
        }


        return directives;
    }

    function toFloat(name, text) {
        let value = parseFloat(text);
        if (!value) {
            throw new Error(`Invalid float value ${text} for ${name}`);
        }

        return value;
    }

    async function apply() {
        let directives;
        try {
            directives = fillDirectives();
        } catch (ex) {
            gUtilities.showError("Read Values", ex.toString());
            return;
        }

        let request = {
            service: "plan.update.directives",
            plan_name: plan.plan_name,
            patient_id: plan.patient_id,
            directives: directives
        };

        let response = await gSession.exchange(request);
        if (response.isError) {
            gUtilities.showError("Update Directives", response.message);
        } else {
            hideModal();
        }
    };

    gEventRouter.register("DirectiveDialog.showModal", showModal);

    async function fetchPlan() {
        let request = {
            service: "plan.find",
            plan_name: job.plan.plan_name,
            patient_id: job.plan.patient_id
        };

        let response = await gSession.exchange(request);

        if (response.isError) {
            gUtilities.showError("Fetch Plan", response.message);
        } else {
            setPlan(response);
        }
    }

    function getWidget(id) {
        let widget = document.querySelector("#" + id);

        return widget;
    }

    const setFields = () => {
        let toggle;
        let widget;

        toggle = getWidget("w_VoidCardSwitch");
        if (toggle) {
            if (plan.directives.VOID) {
                toggle.checked = true;
            } else {
                toggle.checked = false;
            }
        }

        toggle = getWidget("w_FluxCardSwitch");
        if (toggle) {
            if (plan.directives.FLUX) {
                toggle.checked = true;
            } else {
                toggle.checked = false;
            }
        }

        toggle = getWidget("w_UncertaintySwitch");
        if (toggle) {
            if (plan.directives.UNCERTAINTY) {
                toggle.checked = true;
            } else {
                toggle.checked = false;
            }
        }

        toggle = getWidget("w_ProtonParticleSwitch");
        if (toggle) {
            if (plan.directives.PROTON) {
                toggle.checked = true;
            } else {
                toggle.checked = false;
            }
        }

        toggle = getWidget("w_AirSwitch");
        if (toggle) {
            if (plan.directives.AIR) {
                toggle.checked = true;
            } else {
                toggle.checked = false;
            }
        }

        toggle = getWidget("w_MeshSwitch");
        if (toggle) {
            if (plan.directives.MESH) {
                toggle.checked = true;
            } else {
                toggle.checked = false;
            }
        }

        toggle = getWidget("w_NitrogenN14Switch");
        if (toggle) {
            if (plan.directives.NITROGEN_N14_TALLY) {
                toggle.checked = true;
            } else {
                toggle.checked = false;
            }
        }

        toggle = getWidget("w_HydrogenH1TallySwitch");
        if (toggle) {
            if (plan.directives.HYDROGEN_H1_TALLY) {
                toggle.checked = true;
            } else {
                toggle.checked = false;
            }
        }

        toggle = getWidget("w_StatepointRecordSwitch");
        if (toggle) {
            if (plan.directives.STATEPOINT_RECORD) {
                toggle.checked = true;
            } else {
                toggle.checked = false;
            }
        }

        toggle = getWidget("w_XrbeSwitch");
        if (toggle) {
            if (plan.directives.XRBE) {
                toggle.checked = true;
            } else {
                toggle.checked = false;
            }
        }

        toggle = getWidget("w_DiskSwitch");
        if (toggle) {
            widget = getWidget("w_DiskRadiusTextbox");
            if (plan.directives.DISK) {
                toggle.checked = true;
                widget.value = plan.directives.DISK.radius;
            } else {
                toggle.checked = false;
                widget.value = "0.0";
            }
        }

        toggle = getWidget("w_EnergySwitch");
        if (toggle) {
            widget = getWidget("w_EnergyTextbox");
            if (plan.directives.ENERGY) {
                toggle.checked = true;
                widget.value = plan.directives.ENERGY.energy;
            } else {
                toggle.checked = false;
                widget.value = "0.08";
            }
        }

        toggle = getWidget("w_TargetBCRSwitch");
        if (toggle) {
            widget = getWidget("w_TargetBCRTextbox");
            if (plan.directives.TARGET_BCR) {
                toggle.checked = true;
                widget.value = plan.directives.TARGET_BCR.bcr;
            } else {
                toggle.checked = false;
                widget.value = "0.0";
            }
        }

        toggle = getWidget("w_HwdfSwitch");
        if (toggle) {
            widget = getWidget("w_HwdfTextbox");
            if (plan.directives.HWDF) {
                toggle.checked = true;
                widget.value = plan.directives.HWDF.hwdf;
            } else {
                toggle.checked = false;
                widget.value = "0.0";
            }
        }

        toggle = getWidget("w_TallyMultiplierSwitch");
        if (toggle) {
            widget = getWidget("w_TallyMultiplierTextbox");
            if (plan.directives.TALLY_MULTIPLIER) {
                toggle.checked = true;
                widget.value = plan.directives.TALLY_MULTIPLIER.multiplier;
            } else {
                toggle.checked = false;
                widget.value = "0.0";
            }
        }

        toggle = getWidget("w_CountSwitch");
        if (toggle) {
            widget = getWidget("w_CountTextbox");
            if (plan.directives.COUNT) {
                toggle.checked = true;
                widget.value = plan.directives.COUNT.count;
            } else {
                toggle.checked = false;
                widget.value = "1e6";
            }
        }

        toggle = getWidget("w_NodeCountSwitch");
        if (toggle) {
            widget = getWidget("w_NodeCountTextbox");
            if (plan.directives.NODE_COUNT) {
                toggle.checked = true;
                widget.value = plan.directives.NODE_COUNT.count;
            } else {
                toggle.checked = false;
                widget.value = "4";
            }
        }

        toggle = getWidget("w_UsernameSwitch");
        if (toggle) {
            widget = getWidget("w_UsernameTextbox");
            if (plan.directives.USER) {
                toggle.checked = true;
                widget.value = plan.directives.USER.username;
            } else {
                toggle.checked = false;
                widget.value = "username";
            }
        }

        toggle = getWidget("w_NoteSwitch");
        if (toggle) {
            widget = getWidget("w_NoteTextbox");
            if (plan.directives.NOTE) {
                toggle.checked = true;
                widget.value = plan.directives.NOTE.note;
            } else {
                toggle.checked = false;
                widget.value = "comment";
            }
        }

        toggle = getWidget("w_TagSwitch");
        if (toggle) {
            widget = getWidget("w_TagTextbox");
            if (plan.directives.TAG) {
                toggle.checked = true;
                widget.value = plan.directives.TAG.tag;
            } else {
                toggle.checked = false;
                widget.value = "search.filter";
            }
        }

        toggle = getWidget("w_BoronFmExpressionSwitch");
        if (toggle) {
            widget = getWidget("w_BoronFmExpressionTextbox");
            if (plan.directives.BORON_FM_EXPRESSION) {
                toggle.checked = true;
                widget.value = plan.directives.BORON_FM_EXPRESSION.boron_fm_expression;
            } else {
                toggle.checked = false;
                widget.value = kDefaultBoronFmExpression;
            }
        }

        toggle = getWidget("w_GammaFmExpressionSwitch");
        if (toggle) {
            widget = getWidget("w_GammaFmExpressionTextbox");
            if (plan.directives.GAMMA_FM_EXPRESSION) {
                toggle.checked = true;
                widget.value = plan.directives.GAMMA_FM_EXPRESSION.gamma_fm_expression;
            } else {
                toggle.checked = false;
                widget.value = kDefaultGammaFmExpression;
            }
        }

        toggle = getWidget("w_HydrogenFmExpressionSwitch");
        if (toggle) {
            widget = getWidget("w_HydrogenFmExpressionTextbox");
            if (plan.directives.HYDROGEN_FM_EXPRESSION) {
                toggle.checked = true;
                widget.value = plan.directives.HYDROGEN_FM_EXPRESSION.hydrogen_fm_expression;
            } else {
                toggle.checked = false;
                widget.value = kDefaultHydrogenFmExpression;
            }
        }

        toggle = getWidget("w_NitrogenFmExpressionSwitch");
        if (toggle) {
            widget = getWidget("w_NitrogenFmExpressionTextbox");
            if (plan.directives.NITROGEN_FM_EXPRESSION) {
                toggle.checked = true;
                widget.value = plan.directives.NITROGEN_FM_EXPRESSION.nitrogen_fm_expression;
            } else {
                toggle.checked = false;
                widget.value = kDefaultNitrogenFmExpression;
            }
        }
    }

    useEffect(() => {
        try {
            if (plan) {
                setFields();
            } else if (job) {
                fetchPlan(job);
            }
        } catch (ex) {
            gUtilities.showError("Update", ex.toString());
        }
    }, [job, plan]);

    return (
        <div>
            <Modal show={isOpen}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Plan Directives</h5>
                    </div>
                    <div className="modal-body">
                        <ul class="list-group">
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                <span className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="w_VoidCardSwitch"></input>
                                    <label className="form-check-label" htmlFor="w_VoidCardSwitch">VOID card</label>
                                </span>
                                <span class="badge bg-primary rounded-pill">No Interactions</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                <span className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="w_FluxCardSwitch"></input>
                                    <label className="form-check-label" htmlFor="w_FluxCardSwitch">FLUX</label>
                                </span>
                                <span class="badge bg-primary rounded-pill">Uncertainty,Neutron(x3),Photon</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                <span className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="w_UncertaintySwitch"></input>
                                    <label className="form-check-label" htmlFor="w_UncertaintySwitch">Uncertainty</label>
                                </span>
                                <span class="badge bg-primary rounded-pill">Uncertainty Tallies</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                <span className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="w_ProtonParticleSwitch"></input>
                                    <label className="form-check-label" htmlFor="w_ProtonParticleSwitch">Protons</label>
                                </span>
                                <span class="badge bg-primary rounded-pill">No Neutrons</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                <span className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="w_AirSwitch"></input>
                                    <label className="form-check-label" htmlFor="w_AirSwitch">Air</label>
                                </span>
                                <span class="badge bg-primary rounded-pill">Interactions Outside Anatomy</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                <span className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="w_MeshSwitch"></input>
                                    <label className="form-check-label" htmlFor="w_MeshSwitch">Mesh</label>
                                </span>
                                <span class="badge bg-primary rounded-pill">Mesh Tally</span>
                            </li>
                            {isOpen && job.machine.simulator.name === "openmc" &&
                                <>
                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                        <span className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="w_HydrogenH1TallySwitch"></input>
                                            <label className="form-check-label" htmlFor="w_HydrogenH1TallySwitch">Hydrogen Tally</label>
                                        </span>
                                        <span class="badge bg-primary rounded-pill">H1 Only</span>
                                    </li>

                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                        <span className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="w_NitrogenN14Switch"></input>
                                            <label className="form-check-label" htmlFor="w_NitrogenN14Switch">Nitrogen Tally</label>
                                        </span>
                                        <span class="badge bg-primary rounded-pill">N14 Only (n,p)</span>
                                    </li>

                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                        <span className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="w_StatepointRecordSwitch"></input>
                                            <label className="form-check-label" htmlFor="w_StatepointRecordSwitch">Statepoint Record</label>
                                        </span>
                                        <span class="badge bg-primary rounded-pill">Tally CSV File</span>
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                        <span className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="w_NodeCountSwitch"></input>
                                            <label className="form-check-label" htmlFor="w_NodeCountSwitch">Compute Node Count</label>
                                        </span>
                                        <span class="badge bg-primary rounded-pill">
                                            <input type="text" className="form-text-input form-text-input-sm" placeholder="1e6" id="w_NodeCountTextbox"></input>
                                        </span>
                                    </li>
                                </>
                            }
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                <span className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="w_XrbeSwitch"></input>
                                    <label className="form-check-label" htmlFor="w_XrbeSwitch">XRBE</label>
                                </span>
                                <span class="badge bg-primary rounded-pill">Override RBE Values</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                <span className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="w_DiskSwitch"></input>
                                    <label className="form-check-label" htmlFor="w_DiskSwitch">Disk Radius (cm)</label>

                                </span>
                                <span class="badge bg-primary rounded-pill">
                                    <input type="text" className="form-text-input form-text-input-sm" placeholder="cm" id="w_DiskRadiusTextbox"></input>
                                </span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                <span className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="w_EnergySwitch"></input>
                                    <label className="form-check-label" htmlFor="w_EnergySwitch">Particle Energy (Mev)</label>
                                </span>
                                <span class="badge bg-primary rounded-pill">
                                    <input type="text" className="form-text-input form-text-input-sm" placeholder="MeV" id="w_EnergyTextbox"></input>
                                </span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                <span className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="w_CountSwitch"></input>
                                    <label className="form-check-label" htmlFor="w_CountSwitch">Particle Count</label>
                                </span>
                                <span class="badge bg-primary rounded-pill">
                                    <input type="text" className="form-text-input form-text-input-sm" placeholder="1e6" id="w_CountTextbox"></input>
                                </span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                <span className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="w_TargetBCRSwitch"></input>
                                    <label className="form-check-label" htmlFor="w_TargetBCRSwitch">Target BCR</label>
                                </span>
                                <span class="badge bg-primary rounded-pill">
                                    <input type="text" className="form-text-input form-text-input-sm" placeholder="MeV" id="w_TargetBCRTextbox"></input>
                                </span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                <span className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="w_UsernameSwitch"></input>
                                    <label className="form-check-label" htmlFor="w_UsernameSwitch">Username</label>
                                </span>
                                <span class="badge bg-primary rounded-pill">
                                    <input type="text" className="form-text-input form-text-input-sm" placeholder="username" id="w_UsernameTextbox"></input>
                                </span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                <span className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="w_NoteSwitch"></input>
                                    <label className="form-check-label" htmlFor="w_NoteSwitch">Note</label>
                                </span>
                                <span class="badge bg-primary rounded-pill">
                                    <input type="text" className="form-text-input form-text-input-sm" placeholder="comment" id="w_NoteTextbox"></input>
                                </span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                <span className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="w_TagSwitch"></input>
                                    <label className="form-check-label" htmlFor="w_TagSwitch">Tag</label>
                                </span>
                                <span class="badge bg-primary rounded-pill">
                                    <input type="text" className="form-text-input form-text-input-sm" placeholder="search.filter" id="w_TagTextbox"></input>
                                </span>
                            </li>
                            {isOpen && job.machine.simulator.name === "mcnp" &&
                                <>
                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                        <span className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="w_TallyMultiplierSwitch"></input>
                                            <label className="form-check-label" htmlFor="w_TallyMultiplierSwitch">Tally Multiplier</label>
                                        </span>
                                        <span class="badge bg-primary rounded-pill">
                                            <input type="text" className="form-text-input form-text-input-sm" placeholder="1.0" id="w_TallyMultiplierTextbox"></input>
                                        </span>
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                        <span className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="w_HwdfSwitch"></input>
                                            <label className="form-check-label" htmlFor="w_HwdfSwitch">Heavy Water Displacement Factor</label>
                                        </span>
                                        <span class="badge bg-primary rounded-pill">
                                            <input type="text" className="form-text-input form-text-input-sm" placeholder="0.0" id="w_HwdfTextbox"></input>
                                        </span>
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                        <span className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="w_BoronFmExpressionSwitch"></input>
                                            <label className="form-check-label" htmlFor="w_BoronFmExpressionSwitch">Boron FM Expression</label>
                                        </span>
                                        <span class="badge bg-primary rounded-pill">
                                            <input type="text" className="form-text-input form-text-input-sm" placeholder="" id="w_BoronFmExpressionTextbox"></input>
                                        </span>
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                        <span className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="w_GammaFmExpressionSwitch"></input>
                                            <label className="form-check-label" htmlFor="w_GammaFmExpressionSwitch">Gamma FM Expression</label>
                                        </span>
                                        <span class="badge bg-primary rounded-pill">
                                            <input type="text" className="form-text-input form-text-input-sm" placeholder="" id="w_GammaFmExpressionTextbox"></input>
                                        </span>
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                        <span className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="w_HydrogenFmExpressionSwitch"></input>
                                            <label className="form-check-label" htmlFor="w_HydrogenFmExpressionSwitch">Hydrogen FM Expression</label>
                                        </span>
                                        <span class="badge bg-primary rounded-pill">
                                            <input type="text" className="form-text-input form-text-input-sm" placeholder="" id="w_HydrogenFmExpressionTextbox"></input>
                                        </span>
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                        <span className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="w_NitrogenFmExpressionSwitch"></input>
                                            <label className="form-check-label" htmlFor="w_NitrogenFmExpressionSwitch">Nitrogen FM Expression</label>
                                        </span>
                                        <span class="badge bg-primary rounded-pill">
                                            <input type="text" className="form-text-input form-text-input-sm" placeholder="" id="w_NitrogenFmExpressionTextbox"></input>
                                        </span>
                                    </li>
                                </>
                            }
                        </ul>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={hideModal}>Close</button>
                        <button type="button" className="btn btn-primary" onClick={apply}>Apply</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default DirectiveDialog;
