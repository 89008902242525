//
// Singleton Event router.
// Supports a component calling a function in another component.
//
class EventRouter {
    constructor() {
        this.__table = {};
        this.__errorHandler = null;
    }

    // Register an error callback
    errorHandler(callback) {
        this.__errorHandler = callback;
    }

    // Target component lists its callback by name
    register(name, callback) {
        console.log("INFO: EventBus.register", name);
        this.__table[name] = callback;
    }

    // Target component unlists its callback by name
    unregister(name) {
        console.log("INFO: EventBus.unregister", name);
        delete this.__table[name];
    }

    // Client component calls the named callback
    invoke(name, args) {
        let response = false;
        let callback = this.__table[name];

        if (callback) {
            console.log("INFO: EventBus.invoke", name);
            try {
                response = callback(args);
            } catch (ex) {
                if (this.__errorHandler) {
                    this.__errorHandler(name, ex.toString());
                } else {
                    console.log("ERROR: EXCEPTION EventRouter.invoke", name, ex.toString());
                }
            }
        } else {
            console.log("WARNING: EventBus.invoke - not found", name);
        }

        return response;
    }
}

const gEventRouter = new EventRouter();

export default gEventRouter;