import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import gSession from '../script/session.js';
import gUtilities from "../script/utilities.js";
import gDataStore from "../script/data_store.js";
import gEventRouter from "../script/event_router.js";

const Header = () => {
    const [job, setJob] = useState(null);
    const [refresh, setRefresh] = useState(true);
    const [current, setCurrent] = useState("w_HeaderTlsLink");
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

    function onCurrentJob(name, job) {
        setJob(job);
    }
    gDataStore.onStore("CurrentJob", onCurrentJob);

    const onUserLoggedIn = (name, value) => {
        setIsUserLoggedIn(value);
    }
    gDataStore.onStore("user.loggedIn", onUserLoggedIn);

    function onEventError(name, message) {
        gUtilities.showError("Event Callback", `Callback ${name}: ${message}`);
    }
    gEventRouter.errorHandler(onEventError);

    function cssClassName(id) {
        if (id === current) {
            return "nav-link active";
        }

        return "nav-link";
    }

    function onClick(evt) {
        setCurrent(evt.target.id);
    }

    function getUsername() {
        if (isUserLoggedIn) {
            let user = gSession.user();

            return user.username;
        }

        return "Sign In";
    }

    function signInOutButtonTitle() {
        if (isUserLoggedIn) {
            return "Sign Out";
        }

        return "Sign In";
    }

    async function onSignInOutClick() {
        if (isUserLoggedIn) {
            setIsUserLoggedIn(false);

            let widget = document.getElementById("w_HeaderTlsLink");
            widget.click();

            setTimeout(async () => {
                gDataStore.clear();
                await gSession.logout();
                setRefresh(true);
            }, 100);
        } else {
            gEventRouter.invoke("LogInDialog.showModal");
        }

    }

    useEffect(() => {
        if (refresh) {
            setRefresh(false);
        }
    }, [refresh, job]);

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark mb-2">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/" id="w_HeaderTlsLink" onClick={onClick}>TLS</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarColor01">
                    <ul className="navbar-nav me-auto">
                        {isUserLoggedIn &&
                            <>
                                <li className="nav-item">
                                    <Link className={cssClassName("w_HeaderJobsLink")} to="/app/jobs" id="w_HeaderJobsLink" onClick={onClick}>Jobs</Link>
                                </li>
                                {job &&
                                    <>
                                        <li className="nav-item">
                                            <Link className={cssClassName("w_HeaderFilesLink")} to="/app/job/file" id="w_HeaderFilesLink" onClick={onClick}>Files</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className={cssClassName("w_HeaderLogsLink")} to="/app/job/log" id="w_HeaderLogsLink" onClick={onClick}>Logs</Link>
                                        </li>
                                        {job.simulator_name === "openmc" &&
                                            <li className="nav-item">
                                                <Link className={cssClassName("w_HeaderPlotsLink")} to="/app/job/plot" id="w_HeaderPlotsLink" onClick={onClick}>Plots</Link>
                                            </li>
                                        }
                                        <li className="nav-item">
                                            <Link className={cssClassName("w_HeaderDvhLink")} to="/app/job/dvh" id="w_HeaderDvhLink" onClick={onClick}>DVH</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className={cssClassName("w_HeaderReportLink")} to="/app/job/report" id="w_HeaderReportLink" onClick={onClick}>Report</Link>
                                        </li>
                                    </>
                                }
                            </>
                        }
                        {isUserLoggedIn && gSession.isInGroup("admin") &&
                            <>
                                <li className="nav-item">
                                    <Link className={cssClassName("w_HeaderAdminLink")} to="/app/admin" id="w_HeaderAdminLink" onClick={onClick}>Administration</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={cssClassName("w_HeaderClusterLink")} to="/app/cluster" id="w_HeaderClusterLink" onClick={onClick}>Cluster</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={cssClassName("w_HeaderDatabaseLink")} to="/app/database" id="w_HeaderDatabaseLink" onClick={onClick}>Database</Link>
                                </li>
                            </>
                        }
                        {isUserLoggedIn && gSession.isInGroup("system") &&
                            <li className="nav-item">
                                <Link className={cssClassName("w_HeaderCommissioningLink")} to="/app/commissioning" id="w_HeaderCommissioningLink" onClick={onClick}>Commissioning</Link>
                            </li>
                        }
                        <li className="nav-item">
                            <Link className={cssClassName("w_HeaderDocumentationLink")} to="/app/documentation" id="w_HeaderDocumentationLink" onClick={onClick}>Documentation</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={cssClassName("w_HeaderAboutLink")} to="/app/about" id="w_HeaderAboutLink" onClick={onClick}>About</Link>
                        </li>
                    </ul>
                    <div className="d-flex">
                        <input className="form-control me-sm-2" type="text" id="w_HeaderUsernameText" value={getUsername()} readOnly={true}></input>
                        <button className="btn btn-secondary my-2 my-sm-0 text-nowrap" onClick={onSignInOutClick}>{signInOutButtonTitle()}</button>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Header;