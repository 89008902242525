//
// Generic HTML table with bootswatch markup.
//
// NOTE: See Utilities.convertDbResultToTableData() for schema.
//
import gUtilities from '../script/utilities';

function doFormat(item) {
    if (typeof (item) === 'boolean') {
        return item ? "true" : "false";
    }

    if (typeof (item) === 'number') {
        if (item.toString().indexOf('.') >= 0) {
            return item.toFixed(3);
        }
    }

    return item;
}

const TableWidget = (props) => {
    let tmpData = props.data;
    if (!tmpData || !tmpData.header) {
        tmpData = {
            header: [],
            columns: [],
            rows: []
        };
    }

    const tableData = tmpData;
    const buttonData = props.buttonData;
    let indices = [];
    let id = 0;

    // insure that buttons is an array
    if (buttonData && !buttonData.buttons) {
        buttonData.buttons = [buttonData];
    }

    //
    // don't diplay columns :
    //    with '_id' at the end of the data name
    //    with title (header[i]) empty.
    //
    for (let i in tableData.columns) {
        if (!tableData.header[i] || tableData.header[i].length === 0) {
            indices.push(-1);
        } else if (tableData.columns[i].endsWith("_id") || tableData.columns[i].startsWith("_")) {
            indices.push(-1);
        } else {
            indices.push(i);
        }
    }

    function defaultRowClass(row) {
        return "table-info";
    }
    let rowClass = props.rowClassFunction ? props.rowClassFunction : defaultRowClass;

    function buttonStyle(buttonData, row) {
        let style;

        if (buttonData.style) {
            style = buttonData.style(buttonData, toData(row));
        } else {
            style = {};
        }

        return style;
    }

    function toData(row) {
        return gUtilities.convertTableRowToData(tableData, row);
    }

    function cellStyle() {
        let style = {};
        if (props.cellNoWrap) {
            style.whiteSpace = "nowrap";
        }

        return style;
    }

    function headerStyle() {
        let style = {};
        if (props.headerNoWrap) {
            style.whiteSpace = "nowrap";
        }

        return style;
    }

    return (
        <table className="table table-hover">
            <thead>
                <tr>
                    {
                        indices.map((index) => (
                            (index !== -1) && <th scope="col" style={headerStyle()}>{tableData.header[index]}</th>
                        ))
                    }
                    {
                        buttonData &&
                        <th scope="col">{buttonData.label}</th>
                    }
                </tr>
            </thead>
            <tbody>
                {
                    tableData.rows.map((row) => (
                        <tr key={(id++).toString()} className={rowClass(row)}>
                            {
                                indices.map((index) => (
                                    (index !== -1) && <td style={cellStyle()}>{doFormat(row[index])}</td>
                                ))
                            }
                            {
                                buttonData &&
                                buttonData.buttons.map((bdata) =>
                                    <th scope="col"><button type="button" className="btn btn-dark btn-sm" style={buttonStyle(bdata, row)} onClick={() => {
                                        bdata.callback(toData(row));
                                    }}>{bdata.label}</button></th>
                                )
                            }
                        </tr>
                    ))
                }
            </tbody>
        </table>

    );
}

export default TableWidget;