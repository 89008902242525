//
// TLS Dose Engine About Page
//
import { useState, useEffect } from 'react'

import AddUserDialog from './AddUserDialog';
import gEventRouter from "../script/event_router.js";
import gUtilities from '../script/utilities';
import gSession from '../script/session';

const kUpdateTimeoutMs = 1000;

const AdminPage = () => {
    const [data, setData] = useState({"disk_percent": 0});

    function onCreateUser() {
        gEventRouter.invoke("AddUserDialog.showModal", {});
    }

    async function update() {
        let request = {
            service: "admin.job.disk.use"
        };

        let response = await gSession.exchange(request);

        if (!isMounted()) {
            return;
        }

        if (response.isError) {
            gUtilities.showError("Administration Update", "Failed to fetch disk usage: " + response.message);
        }

        let result = {
            "disk_percent": response.percent
        };

        updateJobDiskUsage(response.percent);

        setData(result);
    }

    function scheduleUpdate() {
        let id = setTimeout(() => {
            try {
                update();
            } catch (ex) {
                console.log("ERROR: [EXCEPTION] admin update - ", ex);
            }
        }, kUpdateTimeoutMs);

        return id;
    }

    function updateJobDiskUsage(percent) {
        let widget = document.getElementById('w_JobDiskUsageProgress');
        if (widget) {
            widget.style.width = percent.toString() + "%";
        }
    }

    // detect existance of this page's root <div>
    function isMounted() {
        return gUtilities.isMounted("w_AdminPageRootDiv");
    }

    useEffect(() => {
        let timer_id = scheduleUpdate();

        // cleanup function: stop updates.
        return () => {
            if (!isMounted()) {
                clearTimeout(timer_id);
            }
        };
    }, [data]);

    return (
        <div id="w_AdminPageRootDiv">
            <h1><span></span></h1>
            <div className="card text-white bg-primary mb-3">
                <div className="card-header">User</div>
                <div className="card-body">
                    <button type="button" className="btn btn-primary" id="w_CreateUserButton" onClick={onCreateUser}>Create</button>
                </div>
            </div>
            <div className="card text-white bg-primary mb-3">
                <div className="card-header">Job Disk Usage</div>
                <div className="card-body">
                    <div className="form-group">
                        <label className="col-form-label mt-1" htmlFor="w_JobDiskUsageProgress">{`${data.disk_percent} %`}</label>
                        <div className="progress">
                            <div className="progress-bar progress-bar-striped bg-success" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" value="0" id="w_JobDiskUsageProgress"></div>
                        </div>
                    </div>
                </div>
            </div>
            <AddUserDialog />
        </div>
    );
}

export default AdminPage;