//
// TLS Dose Engine Database page
//
import { useState, useEffect } from "react";

import gSession from '../script/session.js';
import gUtilities from '../script/utilities';

import TableWidget from './TableWidget';

const ClusterPage = () => {
    let empty = { header: [], columns: [], rows: [] };
    const [machineInstanaceData, setMachineInstanaceData] = useState(empty);
    const [computeNodeData, setComputeNodeData] = useState(empty);
    const [slurmJobData, setSlurmJobData] = useState(empty);
    const [machineWarning, setMachineWarning] = useState(false);
    const [machineMessage, setMachineMessage] = useState("");
    const [nodeWarning, setNodeWarning] = useState(false);
    const [nodeMessage, setNodeMessage] = useState("");
    const [slurmWarning, setSlurmWarning] = useState(false);
    const [slurmMessage, setSlurmMessage] = useState("");

    async function updateMachineInstanceData() {
        let request = {
            service: "job.cluster.query"
        };

        let response = await gSession.exchange(request);

        if (response.isError) {
            setMachineWarning(true);
            setMachineMessage("Machine Query: " + response.message);
        } else {
            setMachineWarning(false);
            convertMachineInstanceData(response);
        }
    }

    async function updateComputeNodeData() {
        let request = {
            service: "job.cluster.sinfo"
        };

        let response = await gSession.exchange(request);

        if (response.isError) {
            setNodeWarning(true);
            setNodeMessage("Machine Query: " + response.message);
        } else {
            setNodeWarning(false);
            convertComputeNodeData(response);
        }
    }

    async function updateSlurmJobData() {
        let request = {
            service: "job.slurm.query"
        };

        let response = await gSession.exchange(request);

        if (response.isError) {
            setSlurmWarning(true);
            setSlurmMessage("Slurm Query: " + response.message);
        } else {
            setSlurmWarning(false);
            convertSlurmJobData(response);
        }
    }

    async function command(name, instance) {
        let request = {
            service: `aws.ec2.${name}`,
            instance: instance
        };

        let response = await gSession.exchange(request);

        if (response.isError) {
            gUtilities.showError(request.service, response.message);
        } else {
            console.log(name, "Success", instance.name);
        }
    }

    function convertMachineInstanceData(response) {
        let tableData = gUtilities.convertDbResultToTableData(response);

        gUtilities.setTableHeader(tableData, "status", "worker");

        setMachineInstanaceData(tableData);
    }

    function convertComputeNodeData(response) {
        let tableData = gUtilities.convertDbResultToTableData(response.nodes);

        setComputeNodeData(tableData);
    }

    function convertSlurmJobData(response) {
        let tableData = empty;

        tableData.header.push("Name");
        tableData.columns.push("name");

        tableData.header.push("ID");
        tableData.columns.push("id");

        tableData.header.push("State");
        tableData.columns.push("state");

        tableData.header.push("Time (mm:ss)");
        tableData.columns.push("time");

        tableData.header.push("Outcome");
        tableData.columns.push("outcome");

        let row;
        for (let job of response.jobs) {
            row = [];
            row.push(job.name);
            row.push(job.id);
            row.push(job.state);
            row.push(convertTime(job.time));
            row.push(job.outcome);

            tableData.rows.push(row);
        }

        tableData.rows = tableData.rows.sort((lhs, rhs) => {
            if (lhs[1] > rhs[1]) {
                return -1;
            }

            return 1;
        });

        setSlurmJobData(tableData);
    }

    // Translate milliseconds to mm:ss
    function convertTime(ms) {
        let total_sec = ms / 1000.0;
        let ss = Math.floor(total_sec % 60);
        let mm = Math.floor((total_sec - ss) / 60.0);

        ss = ss.toString();
        if (ss.length < 2) {
            ss = '0' + ss;
        }

        mm = mm.toString();
        if (mm.length < 2) {
            mm = '0' + mm;
        }

        return `${mm}:${ss}`;
    }

    function onStart(item) {
        let instance = item["_cookie"];

        command("start", instance);
    }

    function onStop(item) {
        let instance = item["_cookie"];

        command("stop", instance);
    }

    function startButtonStyle(buttonData, item) {
        let cookie = item["_cookie"];
        if (!cookie) {
            cookie = {};
        }

        let style;
        if (cookie.dcs_type === "Worker" && cookie.state === "stopped" && cookie.enabled) {
            style = { visiblity: "visible" };
        } else {
            style = { visibility: "hidden" };
        }

        return style;
    }

    function stopButtonStyle(buttonData, item) {
        let cookie = item["_cookie"];
        if (!cookie) {
            cookie = {};
        }

        let style;
        if (cookie.dcs_type === "Worker" && cookie.state === "running") {
            style = { visiblity: "visible" };
        } else {
            style = { visibility: "hidden" };
        }

        return style;
    }

    let buttonData = {
        buttons: [
            {
                label: "Start",
                callback: onStart,
                style: startButtonStyle
            },
            {
                label: "Stop",
                callback: onStop,
                style: stopButtonStyle
            }
        ]
    };

    function scheduleUpdate() {
        let id = setTimeout(() => {
            try {
                updateMachineInstanceData();
                updateComputeNodeData();
                updateSlurmJobData();
            } catch (ex) {
                console.log("ERROR: [EXCEPTION] cluster data update - ", ex);
            }
        }, 1000);

        return id;
    }

    function onNodeWarningDismiss() {
        setNodeWarning(false);
    }

    function onSlurmWarningDismiss() {
        setSlurmWarning(false);
    }

    function onMachineWarningDismiss() {
        setMachineWarning(false);
    }

    useEffect(() => {
        let timer_id = scheduleUpdate();

        // cleanup function: stop updates.
        return () => {
            clearTimeout(timer_id);
        };
    }, [computeNodeData]);

    return (
        <div id="w_ClusterPageRootDiv">
            <div>
                <fieldset>
                    <legend>Slurm Jobs</legend>
                    <TableWidget data={slurmJobData} />
                    {
                        slurmWarning &&
                        <div className="alert alert-dismissible alert-warning">
                            <button type="button" className="btn-close" onClick={onSlurmWarningDismiss}></button>
                            <h4 className="alert-heading">Warning!</h4>
                            <p className="mb-0">{slurmMessage}</p>
                        </div>
                    }
                </fieldset>
                <fieldset>
                    <legend>Workers</legend>
                    <TableWidget data={machineInstanaceData} buttonData={buttonData} />
                    {
                        machineWarning &&
                        <div className="alert alert-dismissible alert-warning">
                            <button type="button" className="btn-close" onClick={onMachineWarningDismiss}></button>
                            <h4 className="alert-heading">Warning!</h4>
                            <p className="mb-0">{machineMessage}</p>
                        </div>
                    }
                </fieldset>
                <fieldset>
                    <legend>Compute Nodes</legend>
                    <TableWidget data={computeNodeData} buttonData={buttonData} />
                    {
                        nodeWarning &&
                        <div className="alert alert-dismissible alert-warning">
                            <button type="button" className="btn-close" onClick={onNodeWarningDismiss}></button>
                            <h4 className="alert-heading">Warning!</h4>
                            <p className="mb-0">{nodeMessage}</p>
                        </div>
                    }
                </fieldset>
            </div>
        </div>
    );
}

export default ClusterPage;