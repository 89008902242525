//
// Machine schema definition for commissioning.
// Builds a Validator tree for value validation
// based on the model and simulator type.
//
import Validator from '../script/validator';
import gUtilities from './utilities';

const kMachineSchemaVersion = "0.2";

const kMachineFields = [
    "name",
    "version",
    "machine_id",
    "model",
    "description",
    "timestamp",
    "status",
    "notes"
];

const kMachineBeamFields = [
    "energy",
    "target_yield",
    "proton_current"
];

const kSimulatorFields = [
    "name"
];

const kCollimatorsFields = [
];

const kMachineFiles = [
    "materials"
];

const kMcnpSettingsFields = [
    "particle_count"
];

const kMcnpSsrFields = [
    "ssr_input_particles",
    "ssr_output_particles"
];

const kMcnpSdefFields = [
];

const kMcnpSsrFiles = [
    "rssa"
];

const kMcnpSdefFiles = [
    "sdef"
];

const kOpenMcSettingsFields = [
    "run_mode",
    "batches",
    "seed",
    "electron_treatment",
    "photon_transport",
    "survival_biasing",
    "particle_count",
    "verbosity"
];

const kOpenMcSdefFields = [
    "target_z",
    "source_strength",
    "debug"
];

const kOpenMcSsrFields = [
    "particles_input",
    "particles_output",
];

const kOpenMcSdefFiles = [
    "energy_histogram",
    "position_histogram",
    "shared_library"
];

const kOpenMcSsrFiles = [
    "rssa"
];

const kOpenMcSettingsOutputFields = [
    "summary",
    "tallies"
];

const kOpenMcSettingsCutoffFields = [
    "weight",
    "weight_avg",
    "energy_photon"
];

const kOpenMcSettingsTemperatureFields = [
    "default"
];

const kMcnpSlotFields = [
    "slot",
    "name",
    "size",
    "offset",
    "status"
];

const kMcnpSlotFiles = [
    "surfaces",
    "cells"
];

const kOpenMcSlotFields = [
    "slot",
    "name",
    "size",
    "offset",
    "status"
];

const kOpenMcSlotFiles = [
    "geometry"
];


const kCollimatorCount = 4;  // machine has exactly 4 collimators


class Machine {
    //
    // object record - optional database document or null for new machine
    //
    constructor(record = null) {
        this.__validator = null;

        if (record) {
            this.__validator = new Validator("machine", record);
            this.__restore();
        } else {
            this.__validator = new Validator("machine", kMachineFields);
            this.__initialize();
        }
    }

    name() {
        return this.__validator.value("machine.name");
    }

    model() {
        return this.__validator.value("machine.model");
    }

    simulator_name() {
        return this.__validator.value("machine.simulator.name");
    }

    energy() {
        return this.__validator.value("machine.beam.energy");
    }

    status() {
        return this.__validator.value("machine.status");
    }

    validator() {
        return this.__validator;
    }

    defined() {
        return this.__validator.query("machine.timestamp");
    }

    valid() {
        return this.__validator.allValid();
    }

    beam() {
        return this.child("self.beam");
    }

    simulator() {
        return this.child("self.simulator");
    }

    collimators() {
        return this.child("self.collimators");
    }

    define() {
        let timestamp = new Date();
        this.__validator.validate("machine.timestamp", timestamp.toISOString());

        this.__validator.validate("machine.status", "DEFINED");

        this.__note("Defined");
    }

    tree() {
        return this.__validator.tree();
    }

    //
    // Include fields based on model and simulator selections
    //
    addSettings() {
        let simulator = this.__validator.child("machine.simulator");

        let settings;
        let fileList = kMachineFiles;

        if (this.simulator_name() === "mcnp") {
            settings = new Validator("settings", kMcnpSettingsFields);

            if (this.model() === "SSR") {
            } else {
                this.beam().append(kMcnpSdefFields);
                fileList = fileList.concat(kMcnpSdefFiles);
            }
        } else if (this.simulator_name() === "openmc") {
            settings = new Validator("settings", kOpenMcSettingsFields);

            if (this.model() === "SSR") {
            } else {
                this.beam().append(kOpenMcSdefFields);
                fileList = fileList.concat(kOpenMcSdefFiles);
            }

            settings.branch(new Validator("output", kOpenMcSettingsOutputFields));
            settings.branch(new Validator("cutoff", kOpenMcSettingsCutoffFields));
            settings.branch(new Validator("temperature", kOpenMcSettingsTemperatureFields));
        } else {
            this.__validator.invalidate("machine.simulator.name");
            gUtilities.showError("Machine Files", "Simulator name is invalid");
            return;
        }

        simulator.branch(settings);

        let files = new Validator("files", fileList);
        this.__validator.branch(files);
    }

    // Convert an integer into a slot name; e.g. 1 => slot1
    numberToSlotName(number) {
        return `slot${number}`;
    }

    //
    // Add the production machine collimator validators (4).
    // Call after simulator and model have been specified.
    //
    addStandardCollimators() {
        let count = kCollimatorCount;
        while (count > 0) {
            this.addCollimator();
            count--;
        }
    }

    //
    // Adds a collimator validator.
    //    return - validator for the new collimator
    //
    addCollimator() {
        let collimators = this.__validator.child("machine.collimators");
        let number = collimators.count() + 1;
        let name = this.numberToSlotName(number);
        let cvalidator;
        let files;

        if (this.simulator_name() === "mcnp") {
            if (this.model() === "SSR") {
                cvalidator = new Validator(name, kMcnpSlotFields.concat(kMcnpSsrFields));
                files = new Validator("files", kMcnpSlotFiles.concat(kMcnpSsrFiles));
            } else {
                cvalidator = new Validator(name, kMcnpSlotFields);
                files = new Validator("files", kMcnpSlotFiles);
            }
        } else if (this.simulator_name() === "openmc") {
            if (this.model() === "SSR") {
                cvalidator = new Validator(name, kOpenMcSlotFields.concat(kOpenMcSsrFields));
                files = new Validator("files", kOpenMcSlotFiles.concat(kOpenMcSsrFiles));
            } else {
                cvalidator = new Validator(name, kOpenMcSlotFields);
                files = new Validator("files", kOpenMcSlotFiles);
            }
        }

        cvalidator.validate(`${name}.slot`, name);

        cvalidator.branch(files);
        collimators.branch(cvalidator);

        return cvalidator;
    }

    __initialize() {
        this.__validator.validate('machine.version', kMachineSchemaVersion);
        this.__validator.validate('machine.status', "UNDEFINED");
        this.__validator.validate("machine.notes", []);

        this.__validator.branch(new Validator("beam", kMachineBeamFields));
        this.__validator.branch(new Validator("simulator", kSimulatorFields));
        this.__validator.branch(new Validator("collimators", kCollimatorsFields));

        this.__note("New Machine");
    }

    __restore() {
        let list = this.__validator.list();
        for (let item of list) {
            this.__validator.revalidate(item);
        }

        this.__note("Restored from record");

        this.define();
    }

    __note(text) {
        let date = new Date();
        let timestamp = date.toISOString();

        let note = `[${timestamp}] ${text}`;

        let notes = this.__validator.value("machine.notes");
        notes = notes.concat([note]);   // create new array

        this.__validator.validate("machine.notes", notes);
    }
}

export default Machine;