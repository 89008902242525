//
// Upload a file and return the path on the server.
//
import { useState } from 'react';

import gUtilities from '../script/utilities';
import gEventRouter from "../script/event_router.js";
import gSession from '../script/session';

//
// props:
//   string    title      - label for item
//   string    item       - route to value in Validator; e.g. "machine.files.materials"
//   string    guid       - UUID for umbrella database document; e.g. machine_id
//   string    tag        - identifier for this file; e.g. "materials_file"
//   Validator validator  - class Validator
//
const FileUploader = (props) => {
    const [widgetID] = useState(gUtilities.widgetID());
    const [refresh, setRefresh] = useState(0);

    function onUpload() {
        let data = {
            path: "/system/upload",
            folder: `${props.guid}.${props.tag}`,
            callback: onUploadCallback
        };

        gEventRouter.invoke("FileUploadDialog.showModal", data);
    }

    function onUploadCallback(response) {
        if (response.isError) {
            gUtilities.showError("Upload File", "Upload failed: " + response.message);
            props.validator.invalidate(props.item);
        } else {
            props.validator.validate(props.item, response.path);
        }

        setRefresh(refresh + 1);
    }

    // allow cloning path in dev or test context only
    function allowAssign() {
        let value = props.validator.value(props.item);

        let allow = !props.validator.query(props.item) &&
            !gSession.isProduction() &&
            value && value.length > 0;

        return allow;
    }

    function onAssign() {
        let widget = document.getElementById(widgetID);

        let value = widget ? widget.value : null;
        if (value && value.length > 0) {
            props.validator.validate(props.item, value);
        } else {
            gUtilities.showError("Assign", "Invalid file path");
        }
    }

    function uploadButtonText() {
        return allowAssign() ? "Loaded" : "Upload";
    }

    return (
        <div className="form-group">
            <label className="col-form-label mt-1" htmlFor={widgetID}>{props.title}</label>
            <div className="input-group mb-1">
                <input type="text" className="form-control" id={widgetID} placeholder="Browse to upload" value={gUtilities.parseFilename(props.validator.value(props.item))} readOnly={true}></input>
                <button className="btn btn-primary" type="button" id={widgetID + "UploadButton"} onClick={onUpload}>{uploadButtonText()}</button>
                {allowAssign() &&
                    <button className="btn btn-primary" type="button" id={widgetID + "AssignButton"} onClick={onAssign}>Assign</button>
                }
            </div>
        </div >
    );
}

export default FileUploader;