//
// Gatekeeper class to evaluate a set of Boolean values
// and indicate the total validation state.
// A simple Boolean state machine.
//
// EXAMPLE:
//   In component:
//     const kInputs = [ "name", "address", "title" ];
//     const [refresh, setRefresh] = useState(null);
//     const [validator] = useState(new Validator("InputValidator", kInputs, refresh, setRefresh));
//  
//     function enterNameCallback(evt) {
//         if (evt.target.value.match(/.../)) {
//             validator.validate("name", value);  // will cause render if onChange in constructor
//         }
//     }
//
// In DOM:
//     { validator.query("name") &&
//       <p>Input 'name' is valid</p>
//     }

import gUtilities from "./utilities";

//
class Validator {
    //
    // Constructor
    //   string   name     - identifier for this Validator
    //   string[] items    - array of item (AKA flag) names, or a tree (object) from tree()
    //
    constructor(name, items) {
        this.__name = name;
        this.__items = {};
        this.__count = 0;
        this.__callbacks = [];

        if (gUtilities.isObject(items)) {
            this.__grow(items);
        } else {
            this.__initialize(items);
        }
    }

    //
    // Event function for updates. 
    // In caller:
    //    const [refresh, setRefresh] = useState(null);
    //
    //    if (refresh === null) {
    //        props.validator.pushCallback(setRefresh);
    //        refresh({});
    //    }
    //
    // NOTES: 
    //    1) Call validator.popCallback() when dismounted
    //
    //    2) Only call on child validators if they
    //       need a separate callback
    //
    pushCallback(callback) {
        this.__callbacks.push(callback);

        for (let item of Object.values(this.__items)) {
            if (item instanceof Validator) {
                item.pushCallback(callback);
            }
        }
    }

    // Remove last callback
    popCallback() {
        this.__callbacks.pop();

        for (let item of Object.values(this.__items)) {
            if (item instanceof Validator) {
                item.popCallback();
            }
        }
    }

    // Returns the name of this Validator
    name() {
        return this.__name;
    }

    // Returns the number of items
    count() {
        return Object.keys(this.__items).length;
    }

    // Returns true if item at path exists (regardless of validity)
    exists(path) {
        return this.__traverse(path, false) !== null;
    }

    //
    // Set item 'path' to true
    //   string path - path of item starting with name of this Validator or 'self'
    //                 e.g. "motor.electrical.current" or "self.electrical.current"
    //   return      - true if the item changed value
    //
    validate(path, value) {
        let result = this.__traverse(path);

        return result.validator.__validate(result.name, value);
    }

    //
    // Validate item using existing value.
    // Will throw if value is null.
    // Typically used with a restored database record.
    //
    //   string path - path of item
    //   return      - true if the item changed validity
    //
    revalidate(path) {
        let result = this.__traverse(path);

        return result.validator.__revalidate(result.name);
    }

    //
    // Set item 'path' to false
    //   string path - path of item
    //   return      - true if the item changed validity
    //
    invalidate(path) {
        let result = this.__traverse(path);

        return result.validator.__invalidate(result.name);
    }

    //
    // Returns the validation state of specified item
    //   string path - path of item
    //
    query(path) {
        let result = this.__traverse(path);

        return result.validator.__query(result.name);
    }

    //
    // Returns the value of specified item
    //   string path - path of item
    //
    value(path) {
        let result = this.__traverse(path);

        return result.validator.__value(result.name);
    }

    //
    // Returns true when all items are valid
    //
    allValid() {
        for (let name of Object.keys(this.__items)) {
            if (!this.__query(name)) {
                return false;
            }
        }

        return true;
    }

    //
    // Returns true when at least one item is valid (true)
    //
    anyValid() {
        for (let name of Object.keys(this.__items)) {
            if (this.__query(name)) {
                return true;
            }
        }

        return false;
    }

    // Returns an array of item names
    names() {
        return Object.keys(this.__items);
    }

    //
    // Returns a list of items
    //   bool valid - [optional] filter by valid (true) or invalid (false)
    //
    list(valid = -1) {
        let items = [];
        let item;
        let subs;

        for (let key of Object.keys(this.__items)) {
            item = this.__items[key];

            if (item instanceof Validator) {
                subs = item.list(valid);
                subs.forEach((sub) => {
                    items.push(`${this.__name}.${sub}`);
                });
            } else {
                if (valid === -1 || item.valid === valid) {
                    items.push(`${this.__name}.${key}`);
                }
            }
        }

        return items;
    }

    // Returns an table with items as keys and isValid as value
    report() {
        let valids = this.list(true);
        let invalids = this.list(false);

        let result = {}
        for (let item of valids) {
            result[item] = true;
        }

        for (let item of invalids) {
            result[item] = false;
        }

        return result;
    }

    //
    // Returns a child Validator node.
    //   string path - path of child Validator
    //
    child(path) {
        let data = this.__traverse(path);

        return data.validator.__items[data.name];
    }

    //
    // Returns the Validator tree as an object.
    //
    tree() {
        let node = {};

        Object.values(this.__items).forEach((item) => {
            if (item instanceof Validator) {
                node[item.name()] = item.tree();
            } else {
                node[item.name] = item.value;
            }
        });

        return node;
    }

    // Add items to validation list
    append(items) {
        if (typeof (items) === "string") {
            items = [items];
        }

        for (let item of items) {
            this.__create(item);
            this.__update(item, null, false);
        }
    }

    // Extend to include additional (child) validator
    branch(validator) {
        if (this.__items.hasOwnProperty(validator.name())) {
            throw Error(`Attempted to branch ${this.__name} with a second Validator ${validator.name()} to Validator ${this.__name}`);
        }

        this.__items[validator.name()] = validator;

        for (let callback of this.__callbacks) {
            validator.pushCallback(callback);
        }
    }

    // Private =========================================

    __invalidate(name) {
        if (!this.__items.hasOwnProperty(name)) {
            throw new Error(`[invalidate] Validator ${this.__name} has no item '${name}'`);
        }

        if (!this.__items[name].valid) {
            return false;
        }

        return this.__update(name, null, false);
    }

    __revalidate(name) {
        if (!this.__items.hasOwnProperty(name)) {
            throw new Error(`[revalidate] Validator ${this.__name} has no item '${name}'`);
        }

        if (this.__items[name].value === null) {
            throw new Error(`[revalidate] Cannot revalidate a null value for '${name}`);
        }

        return this.__update(name, this.__items[name].value, true);
    }

    __validate(name, value) {
        if (!this.__items.hasOwnProperty(name)) {
            throw new Error(`[validate] Validator ${this.__name} has no item '${name}'`);
        }

        if (value === null || value === undefined) {
            throw new Error(`[validate] Validator ${this.__name} item '${name}' cannot be null`);
        }

        return this.__update(name, value, true);
    }

    __value(name, value) {
        if (!this.__items.hasOwnProperty(name)) {
            throw new Error(`[value] Validator ${this.__name} has no item '${name}'`);
        }

        return this.__items[name].value;
    }

    __query(name) {
        if (!this.__items.hasOwnProperty(name)) {
            throw new Error(`[valid] Validator ${this.__name} has no item '${name}'`);
        }

        if (this.__isValidator(name)) {
            return this.__items[name].allValid();
        } else {
            return this.__items[name].valid;
        }
    }

    // Initialze the values in the validator graph without validating
    //    tree - object with full or partial tree of values
    __grow(tree) {
        let value;
        let child;

        for (let name of Object.keys(tree)) {
            if (name === "_id") {
                continue;
            }

            value = tree[name];
            if (gUtilities.isObject(value)) {
                child = new Validator(name, value);
                this.branch(child);
            } else {
                this.__create(name, value);
            }
        }
    }

    __initialize(items) {
        this.__items = {};

        for (let item of items) {
            this.__create(item);
        }
    }

    __create(name, value = null) {
        this.__items[name] = {
            name: name,
            value: value,
            valid: false
        };
    }

    __isValidator(name) {
        return this.__items.hasOwnProperty(name) && this.__items[name] instanceof Validator;
    }

    __isData(name) {
        return this.__items.hasOwnProperty(name) && !(this.__items[name] instanceof Validator);
    }

    __traverse(path, doThrow = true) {
        let names = path.split('.');
        if (names[0] === "self") {
            names[0] = this.__name;
        }

        let data = this.__find(names);

        if (typeof (data) === "number") {
            if (!doThrow) {
                return null;
            }

            let error;
            switch (data) {
                case 1:
                    error = new Error(`[validator.path] Invalid path (1) ${path}`);
                    break;

                case 2:
                    error = new Error(`[validator.path] Invalid path (2) ${path}`);
                    break;

                case 3:
                    error = new Error(`[validator.path] Item not found ${path}`);
                    break;

                default:
                    error = new Error(`[validator.path] Invalid path (parse) ${path}`);
            }

            throw error;
        }

        return data;
    }

    __find(names) {
        if (names.length < 2) {
            return 1;  // invalid path
        }

        let name = names.shift();
        if (name !== this.__name) {
            return 3;  // not found
        }

        name = names[0];  // no shift

        let data;
        if (names.length === 1) {
            if (this.__items.hasOwnProperty(name)) {
                data = {
                    validator: this,
                    name: name
                };
            } else {
                return 3;  // not found
            }
        } else if (this.__isValidator(name)) {
            data = this.__items[name].__find(names);
        } else {
            return 2;  // invalid path
        }

        return data;
    }

    __update(name, value, valid) {
        let data = this.__items[name];
        let changed = data.value !== value || data.valid !== valid;

        if (changed) {
            this.__items[name].value = value;
            this.__items[name].valid = valid;

            for (let callback of this.__callbacks) {
                callback({
                    "name": name,
                    "value": value,
                    "valid": valid,
                    "timestamp": gUtilities.nowIso()
                });
            }
        }

        return changed;
    }
}

export default Validator;
