import { useState, useEffect } from 'react'

import gUtilities from '../script/utilities';

//
// Documentation access for TLS Dose Engine.
//
// Each tab in the page fetches content from a static HTML file.
//

const DocumentationPage = () => {
    const [content, setContent] = useState(null);

    async function fetchContent(path) {
        let html;

        try {
            const res = await fetch(path);
            html = await res.text();
        } catch (ex) {
            gUtilities.showError("Fetch Content", ex.toString());
            html = "<h2>ERROR: Failed to load content from server</h2>";
        }

        setContent(html);
    }

    useEffect(() => {
        let widget;

        if (!content) {
            widget = document.querySelector("#w_DocumentationEngineA");
            widget.click();
        } else {
            widget = document.querySelector("#w_DocumentationContentDiv");
            widget.innerHTML = content;
        }
    }, [content]);

    return (
        <div>
            <h1>Dose Engine Documentation</h1>
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <a className="nav-link active" data-bs-toggle="tab" href="#w_DocumentationContentDiv" id="w_DocumentationEngineA"
                        onClick={() => fetchContent("/doc/engine.html")}>Engine</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#w_DocumentationContentDiv"
                        onClick={() => fetchContent("/doc/planning.html")}>Planning</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#w_DocumentationContentDiv"
                        onClick={() => fetchContent("/doc/jobs.html")}>Jobs</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#w_DocumentationContentDiv"
                        onClick={() => fetchContent("/doc/users.html")}>Users</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#w_DocumentationContentDiv"
                        onClick={() => fetchContent("/doc/directives.html")}>Directives</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#w_DocumentationContentDiv"
                        onClick={() => fetchContent("/doc/faq.html")}>FAQ</a>
                </li>
            </ul>
            <div id="w_DocumentationDiv" className="tab-content">
                <div className="tab-pane fade active show" id="w_DocumentationContentDiv"></div>
            </div>
        </div >
    );
}

export default DocumentationPage;