//
// TLS Dose Engine Job Report page
//
import { useState } from 'react'

import gSession from '../script/session';
import gUtilities from '../script/utilities';
import gDataStore from "../script/data_store.js";

import TableWidget from './TableWidget';

const k_header = [
    "ROI Name",
    "Converged",
    "Passing Fraction",
    "Passing Voxels",
    "Total Voxels",
    "Unc. Min.",
    "Unc. Median",
    "Unc. Mean",
    "Unc. Max.",
    "Max. Dose",
    "Min. Dose"
];

const k_columns = [
    "name",
    "converged",
    "passing_fraction",
    "passing_voxels",
    "total_voxels",
    "umin",
    "umedian",
    "umean",
    "umax",
    "max_dose",
    "min_dose"
];

function toRow(roi, fields) {
    let row = [];
    let value;

    for (let column of fields) {
        value = roi[column];
        if (!roi.hasOwnProperty(column)) {
            value = "TODO";  // for missing gamma
        }

        if (typeof (value) === 'number' && value.toString().indexOf('.') !== -1) {
            value = value.toFixed(3);
        } else if (typeof (value) === 'boolean') {
            value = value ? "true" : "false";
        } else if ((column === "max_dose" || column === "min_dose") && value.hasOwnProperty("result")) {
            value = `${value.result.toFixed(3)} (${value.uncertainty.toFixed(3)})`;
        }

        row.push(value);
    }

    return row;
}

function build(rois) {
    let result = { header: k_header, columns: k_columns, rows: [] };
    let row;

    for (let roi of rois) {
        row = toRow(roi, result.columns);
        result.rows.push(row);
    }

    let i = gUtilities.tableColumnIndex(result, "passing_fraction");
    result.rows.sort((l, r) => {
        if (l[i] < r[i]) {
            return 1;
        }

        return -1;
    });

    return result;
}

const JobReportPage = () => {
    let empty = { header: [], columns: [], rows: [] };
    const [job, setJob] = useState(null);
    const [banner, setBanner] = useState(empty);
    const [report, setReport] = useState(empty);
    const [boron, setBoron] = useState(empty);
    const [gamma, setGamma] = useState(empty);
    const [hydrogen, setHydrogen] = useState(empty);
    const [nitrogen, setNitrogen] = useState(empty);

    let currentJob = gDataStore.retrieve("CurrentJob", null);

    function display(areport) {
        let result;

        result = build(areport.boron);
        setBoron(result);

        result = build(areport.gamma);
        setGamma(result);

        result = build(areport.hydrogen);
        setHydrogen(result);

        result = build(areport.nitrogen);
        setNitrogen(result);
    }

    async function retrieve(beam_number) {
        let request = {
            service: "job.report",
            job_id: currentJob.job_id,
            beam_number: beam_number
        };

        let response = await gSession.exchange(request);

        if (response.isError) {
            gUtilities.showError("Report Retrieval Error", response.message);
        } else {
            setReport(response);
            display(response);
        }
    }

    if (!job) {
        setJob(currentJob);
        updateBanner(currentJob);

        retrieve(1);
    }

    function updateBanner(job) {
        setBanner({
            header: ["Fingerprint", "Plan Name", "Job State", "Machine Name", "Simulator"],
            columns: ["fingerprint", "plan_name", "state", "machine_name", "simulator_name"],
            rows: [
                [gUtilities.fingerprint(job.job_id), job.plan_name, job.state, job.machine_name, job.simulator_name]
            ]
        });
    }

    function rowClass(row) {
        let index = -1;
        let name;
        for (let i in k_columns) {
            name = k_columns[i];
            if (name === "converged") {
                index = i;
                break;
            }
        }

        // row from TableWidget has string for bools
        if (row[index] === "true") {
            return "table-success";
        } else {
            return "table-info";
        }
    };

    function headline1() {
        return `RayStation Uncertainty ${report.rs_uncertainty}`;
    }

    function headline2() {
        return `Convergence Threshold ${report.convergence_threshold}`;
    }

    function headlineStyle() {
        let style = {
            marginLeft: "auto",
            marginRight: "auto",
            width: "80%",
            border: "3px solid black",
            padding: "10px",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row"
        };

        return style;
    }

    return (
        <div id="w_JobReportPageRootDiv">
            <div className="alert alert-dismissible alert-warning">
                <h4 className="alert-heading">Job Report</h4>
                <TableWidget data={banner} />
            </div>

            <h3 style={headlineStyle()}><div>{headline1()}</div><div>{headline2()}</div></h3>

            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <a className="nav-link active" data-bs-toggle="tab" href="#w_ReportContentDiv" id="w_DocumentationEngineA"
                        onClick={() => retrieve(1)}>Beam 1</a>
                </li>

                {job && job.nbeams > 1 &&
                    <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="tab" href="#w_ReportContentDiv"
                            onClick={() => retrieve(2)}>Beam 2</a>
                    </li>
                }

                {job && job.nbeams > 2 &&
                    <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="tab" href="#w_ReportContentDiv"
                            onClick={() => retrieve(3)}>Beam 3</a>
                    </li>
                }
            </ul>

            <div id="w_ReportContentDiv">
                <fieldset>
                    <legend>Boron</legend>
                    <TableWidget data={boron} rowClassFunction={rowClass} cellNoWrap={true} />
                </fieldset>
                <fieldset>
                    <legend>Gamma</legend>
                    <TableWidget data={gamma} rowClassFunction={rowClass} cellNoWrap={true} />
                </fieldset>
                <fieldset>
                    <legend>Hydrogen</legend>
                    <TableWidget data={hydrogen} rowClassFunction={rowClass} cellNoWrap={true} />
                </fieldset>
                <fieldset>
                    <legend>Nitrogen</legend>
                    <TableWidget data={nitrogen} rowClassFunction={rowClass} cellNoWrap={true} />
                </fieldset>
            </div>
        </div>
    );
}

export default JobReportPage;