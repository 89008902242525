//
// Error dialog.
//
// https://www.pluralsight.com/guides/working-with-bootstraps-modals-react
//
import 'bootstrap/dist/js/bootstrap.min.js';
import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from 'react';

import gEventRouter from "../script/event_router.js";
import gUtilities from '../script/utilities';

const ConfirmationDialog = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [details, setDetails] = useState(null);

    //
    // data.action   - the operation to be performed
    // data.message  - details of the operation
    // data.callback - function with one Boolean argument: true=YES or false=NO
    // 
    const showModal = (data) => {
        if (!data || !data.action || !data.message || !data.callback) {
            gUtilities.showError("Invalid Input", "ConfirmationDialog call is defective");
            return;
        }

        setDetails(data);
        setIsOpen(true);
    };

    const hideModal = () => {
        setIsOpen(false);
    };

    function notify(decision) {
        try {
            details.callback(decision);
        } catch (ex) {
            gUtilities.showError(details.action, "Operation failed (callback) " + ex.toString());
        }

        hideModal();
    }

    function onNo() {
        notify(false);
    }

    function onYes() {
        notify(true);
    }

    gEventRouter.register("ConfirmationDialog.showModal", showModal);

    useEffect(() => {
        if (isOpen) {
            document.querySelector("#w_ConfirmationAction").value = details.action;
            document.querySelector("#w_ConfirmationMessage").value = details.message;
        }
    }, [isOpen, details]);

    return (
        <div>
            <Modal show={isOpen}>
                <div className="modal-content">
                    <div className="modal-header text-danger">
                        <h5 className="modal-title text-danger">Confirmation Required</h5>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label className="col-form-label mt-4" htmlFor="w_ConfirmationAction">Action</label>
                            <input type="text" className="form-control" id="w_ConfirmationAction"></input>
                        </div>
                        <div className="form-group has-danger">
                            <label className="form-label mt-4" htmlFor="w_ConfirmationMessage">Message</label>
                            <input type="text" value="wrong value" className="form-control is-invalid" id="w_ConfirmationMessage"></input>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={onNo}>No</button>
                        <button type="button" className="btn btn-primary" onClick={onYes}>Yes</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default ConfirmationDialog;